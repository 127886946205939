import { AxiosInstance } from 'axios';
import { ApiModule } from '~/clientApi/ApiModule';
import { ExtendedApiModule } from '~/clientApi/clientApi.model';

import { InvitationsApiModule } from './invitations.model';

const MODULE = 'invitations';

const invitations = (apiInstance: AxiosInstance) => {
  const extraEndpoints = {
    accept: `POST ${MODULE}/:id/accept`,
    decline: `POST ${MODULE}/:id/decline`,
    reauth: `POST ${MODULE}/:id/reauth`,
    remove: `POST ${MODULE}/:id/remove`,
    resend: `POST ${MODULE}/:id/resend`,
    revoke: `POST ${MODULE}/:id/revoke`,
    rescind: `POST ${MODULE}/:id/cancel`,
  };

  return new ApiModule(
    apiInstance,
    MODULE,
    extraEndpoints,
  ) as ExtendedApiModule<keyof typeof extraEndpoints> as InvitationsApiModule;
};

export default invitations;
