export enum GENDER {
  men = 'male',
  other = 'other',
  women = 'female',
  nonBinary = 'nonbinary',
}

export enum GENDER_NAMES {
  men = 'men',
  other = 'other',
  women = 'women',
}

export enum AUDIENCE_GENDER {
  men = 'M',
  female = 'F',
  nonBinary = 'NB',
}

export const AUDIENCE_GENDER_NAME = {
  [AUDIENCE_GENDER.men]: 'Men',
  [AUDIENCE_GENDER.female]: 'Female',
  [AUDIENCE_GENDER.nonBinary]: 'Non Binary',
} as const;

export enum QUERY_STATUS {
  error = 'error',
  idle = 'idle',
  loading = 'loading',
  success = 'success',
}

export enum MEDIA_PACK_TEMPLATE {
  default = 'default',
  legacy = 'legacy',
}

export enum ASSET_UPLOAD_TYPE {
  brandLogo = 'brand-logo',
  mpCoverImage = 'mp-cover-image',
  agencyLogo = 'agency-logo',
}

export const FOAM_PREFIX = 'foam';
