import { getMainPlatformData } from '~/creatorUtils';
import { CreatorType } from '~/types';
import {
  getCreatorPlatformConnectionStatus,
  getInstagramStatus,
  getTiktokStatus,
  getYoutubeStatus,
} from '~/utils';

interface State {
  creator: CreatorType;
}

export const selectInstagramStatus = ({ creator }: State) =>
  getInstagramStatus(creator);

export const selectTiktokStatus = ({ creator }: State) =>
  getTiktokStatus(creator);

export const selectYoutubeStatus = ({ creator }: State) =>
  getYoutubeStatus(creator);

export const selectSocialPlatformsConnectionStatus = ({ creator }: State) =>
  getCreatorPlatformConnectionStatus(creator);

export const selectCreatorMainPlatformData = ({ creator }: State) =>
  getMainPlatformData(creator);

export const selectCreator = (state: State) => state.creator;
