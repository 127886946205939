import { AxiosInstance } from 'axios';
import { ApiModule } from '~/clientApi/ApiModule';

import { OffPlatformApiModule } from './offPlatform.model';

const MODULE = 'creator-off-platforms';

const offPlatform = (apiInstance: AxiosInstance) => {
  const extraEndpoints = {
    deleteCreator: {
      method: 'DELETE',
      url: `/creator-off-platforms/:id`,
    },
  };

  return new ApiModule(
    apiInstance,
    MODULE,
    extraEndpoints,
  ) as OffPlatformApiModule;
};

export default offPlatform;
