import { AxiosInstance } from 'axios';

export type CreatorsResponse = {
  results: {}[];
};

const agenciesManagement = (apiInstance: AxiosInstance) => {
  const creators = ({
    agencyId,
    page,
    pageSize,
    properties,
  }: {
    agencyId: string;
    properties?: string[];
    page: number;
    pageSize: number;
  }): Promise<CreatorsResponse> => {
    properties = [
      'fullName',
      'email',
      'firstName',
      'lastName',
      'agency',
      'platforms',
      'createdAt',
      'profilePicUrl',
      'id',
      'coManagers',
      'manager',
      'isManagerOwner',
      'isCoManager',
      'isOffPlatform',
      'avatar',
      'slug',
      'inviteStatus',
      ...(properties || []),
    ];
    return apiInstance.get(`/agencies-management/${agencyId}/creators`, {
      params: {
        page,
        pageSize,
        properties,
        'properties[coManagers][coManager][]': [
          'id',
          'name',
          'email',
          'agency',
        ],
        'properties[manager][]': ['id', 'name', 'email'],
        'properties[invitations][]': ['id', 'email'],
      },
    });
  };

  return {
    creators,
  };
};

export default agenciesManagement;
