import { UserMetricsResponse } from '~/clientApi/metrics';

import { METRICS_INTERCOM_NAMES } from './IntercomContext.constants';
import * as viewModel from './IntercomContext.vm';

export const mapMetricsFromApiToVm = ({
  userId,
  userEmail,
  user_hash,
}: UserMetricsResponse['metrics']): viewModel.UserMetrics => ({
  [METRICS_INTERCOM_NAMES.userId]: userId,
  [METRICS_INTERCOM_NAMES.userEmail]: userEmail,
  [METRICS_INTERCOM_NAMES.userHash]: user_hash,
});
