import atob from 'atob';
import btoa from 'btoa';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isEmpty, sum, sumBy } from 'lodash';
import numeral from 'numeral';
import * as React from 'react';
import { EMAIL_REGEX } from '~/components/EmailInput/EmailInput.constants';
import {
  CURRENCIES,
  DESKTOP_MIN_WIDTH,
  INSTAGRAM_CONTENT_TYPES,
  LARGE_DESKTOP_MIN_WIDTH,
  LONG_DATETIME_FORMAT,
  LONG_DATE_FORMAT,
  MANAGER_INVITATION_STATUS,
  MEDIA_TYPES,
  MOBILE_MIN_WIDTH,
  PARTIALLY_SUPPORTED_PLATFORMS,
  SOCIAL_PLATFORMS,
  SOCIAL_PLATFORMS_CONNECTION_STATUS,
  TABLET_MIN_WIDTH,
} from '~/constants';
import {
  getCreatorInstagramData,
  getCreatorTiktokData,
  getCreatorYoutubeData,
} from '~/creatorUtils';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

export const humanizeDate = (ISOdate) => dayjs().to(ISOdate);

export const formatDate = (ISOdate, options = LONG_DATE_FORMAT) => {
  const parsedISODate =
    ISOdate instanceof Date ? ISOdate : new Date(ISOdate).getTime();

  return dayjs(parsedISODate).isValid()
    ? new Intl.DateTimeFormat(
        [...navigator.languages, 'en-US'],
        options,
      ).format(parsedISODate)
    : '';
};

export const formatDateTime = (ISOdate) => {
  const parsedISODate = dayjs(ISOdate);

  return dayjs(parsedISODate).isValid()
    ? new Intl.DateTimeFormat(
        [...navigator.languages, 'en-US'],
        LONG_DATETIME_FORMAT,
      ).format(parsedISODate)
    : '';
};

export const getUnixTime = (ISOdate) => dayjs(ISOdate).unix();
export const getEpochDate = (epoch) => new Date(epoch * 1000);

/** Formats a Number into a string representing an integer
 * with correct thousands separator
 * @param {Number} number
 * @returns {String}
 * */
export const formatInteger = (number, defaultValue = 'n/a') =>
  Number.isFinite(number) ? numeral(number).format('0,0') : defaultValue;

/** Formats a Number into a string representing a number with abbreviation
 * @param {Number=} number
 * @param {String} defaultValue
 * @returns {String}
 * */
export const formatAbbr = (number, defaultValue = '') =>
  Number.isFinite(number) ? numeral(number).format('0[.]0a') : defaultValue;

/** Formats a number-like value (either type Number or String)
 * into its unsigned version:
 * "-23,000" --> "23,000"
 * -23000 --> "23,000"
 * null -->  null
 * undefined --> undefined
 *
 * @param {String|Number} value
 * @returns {String} formatted value
 */
export const formatUnsignedInteger = (value) => {
  if (typeof value === 'string') {
    return numeral(value.replace('-', '')).format('0,0');
  }
  if (Number.isFinite(value)) {
    return formatInteger(Math.abs(value));
  }

  return value;
};

/** Formats Number into String with max 2 decimals
 * @param {Number} number
 * @returns {String}
 */
export const formatDecimal = (number, defaultValue = 'n/a') =>
  Number.isFinite(number) ? numeral(number).format('0,0[.][0]0') : defaultValue;

/**
 * Formats Number in the range 0-1 into String with percentage
 * @param {number=} value - The number to be rounded
 * @param {String=} defaultValue - The default value if number is not a valid number
 * @param {number=} numberOfDecimals - The number of decimals to be rounded
 * @returns {String}
 */
export const formatPercentage = (
  value,
  defaultValue = 'n/a',
  numberOfDecimals = 2,
) =>
  Number.isFinite(value)
    ? numeral(value).format(`0[.][${''.padStart(numberOfDecimals, '0')}]%`)
    : defaultValue;

export const isStoryContent = (content) =>
  content?.channel === INSTAGRAM_CONTENT_TYPES.story;

export const isPostContent = (content) =>
  content?.channel === INSTAGRAM_CONTENT_TYPES.post;

export const isReelContent = (content) =>
  content?.channel === INSTAGRAM_CONTENT_TYPES.reels;

export const isCarouselContent = (content) => content?.assets?.length > 1;

export const isVideoAsset = (asset) => asset?.type === MEDIA_TYPES.video;

export const isImageAsset = (asset) => asset?.type === MEDIA_TYPES.image;

/**
 * @param {string} str
 * @returns {bool} whether str has lower case characters or not
 */
export const stringHasLowerCase = (str) =>
  str ? str.toUpperCase() !== str : false;

/**
 * @param {string} str
 * @returns {bool} whether str has upper case characters or not
 */
export const stringHasUpperCase = (str) =>
  str ? str.toLowerCase() !== str : false;

/**
 * @param {string} str
 * @returns {bool} whether str has numbers or not
 */
export const stringHasNumber = (str) => /[0-9]/.test(str);

export function isValidEmail(email) {
  return EMAIL_REGEX.test(String(email).toLowerCase());
}

export const isMobileWidth = () =>
  typeof window === 'object' && window.innerWidth < MOBILE_MIN_WIDTH;

export const isTabletWidth = () =>
  typeof window === 'object' &&
  window.innerWidth >= MOBILE_MIN_WIDTH &&
  window.innerWidth < TABLET_MIN_WIDTH;

export const isSmallDesktopWidth = () =>
  typeof window === 'object' &&
  window.innerWidth >= TABLET_MIN_WIDTH &&
  window.innerWidth < DESKTOP_MIN_WIDTH;

export const isLargeDesktopWidth = () =>
  typeof window === 'object' &&
  window.innerWidth >= DESKTOP_MIN_WIDTH &&
  window.innerWidth < LARGE_DESKTOP_MIN_WIDTH;

export const isXLDesktopWidth = () =>
  typeof window === 'object' && window.innerWidth >= LARGE_DESKTOP_MIN_WIDTH;

export const SITE_URL =
  process.env.NEXT_PUBLIC_APP_DOMAIN ||
  [
    process.env.NEXT_PUBLIC_SERVERLESS_SUBDOMAIN,
    process.env.NEXT_PUBLIC_SERVERLESS_DOMAIN,
  ]
    .filter(Boolean)
    .join('.') ||
  'localhost:3000';

/** returns universal url for both client and server */
export const getReqUrl = (ctx) => {
  return ctx?.req?.url || ctx?.asPath;
};

/** calculates total completion rate of a multiframe
 * @param {Object[]} contents
 * @returns {Number} on a scale [0,1]
 */
export const getTotalCompletionRate = (contents) =>
  contents[contents.length - 1]?.reach / contents[0]?.reach;

/** calculates total reach of a multiframe
 * @param {Object[]} contents
 * @returns {Number}
 */
export const getMultiframeTotalReach = (contents) => contents[0]?.reach;

/** calculates total impressions
 * @param {Object[]} contents
 * @returns {Number}
 */
export const getTotalImpressions = (contents) => sumBy(contents, 'impressions');

/** calculates total swipe ups
 * @param {Object[]} contents
 * @returns {Number}
 */
export const getTotalSwipeUps = (contents) => sumBy(contents, 'swipeUps');

/** Calculates completion rate for a story based on past insights
 * @param {Object[]} contents Array of contents
 * @param {String} id Id of content
 * @returns {Number} completion rate
 */
export const getCompletionRateById = (contents, id) => {
  const firstFrameReach = contents[0]?.reach;
  const currentFrameReach = contents.find((item) => item.id === id)?.reach;
  return currentFrameReach / firstFrameReach;
};

export const sortContentsByPostedAt = (a, b) =>
  new Date(a.postedAt).getTime() - new Date(b.postedAt).getTime();

/**
 * Encodes a string following Base64URL standard.
 *
 * https://base64.guru/standards/base64url
 *
 * @param {string} str
 * @returns {string}
 */
export const encodeBase64Url = (str) =>
  btoa(str).replace(/\//g, '_').replace(/\+/g, '-').replace(/=/g, '');

/**
 * Decodes a string from Base64URL standard
 *
 * @param {string} str
 * @returns {string}
 */
export const decodeBase64Url = (str) =>
  atob(str.replace(/_/g, '/').replace(/-/g, '+'));

/**
 * Returns social platform from url
 *
 * @param {string} str
 * @returns {string}
 */
export const getPlatformFromUrl = (url) => {
  if (!url) {
    return undefined;
  }
  if (url.includes('instagram.com')) {
    return 'instagram';
  }
  if (url.includes('youtube.com')) {
    return 'youtube';
  }
  if (url.includes('facebook.com')) {
    return 'facebook';
  }
  if (url.includes('twitter.com')) {
    return 'twitter';
  }
  if (url.includes('tiktok.com')) {
    return 'tiktok';
  }
  return undefined;
};

/**
 * Returns array with item updated at index
 * @param {Array} list
 * @param {Any} updatedItem
 * @param {Number} index
 * @returns {Array}
 */
export const updateArrayAt = (list, updatedItem, index) => {
  return [...list.slice(0, index), updatedItem, ...list.slice(index + 1)];
};

export const shouldDisplayCurrencyCode = (currencyValue) =>
  !['USD', 'EUR', 'GBP'].includes(currencyValue);

export const getCurrencySymbol = (currencyValue) =>
  CURRENCIES[currencyValue]?.symbol;

export const getCurrencyDisplay = (currencyValue) => {
  if (!currencyValue) {
    return '';
  }
  if (!shouldDisplayCurrencyCode(currencyValue)) {
    return CURRENCIES[currencyValue]?.symbol;
  }

  return [CURRENCIES[currencyValue]?.code, CURRENCIES[currencyValue]?.symbol]
    .filter(Boolean)
    .join(' ');
};

export const getEngagements = (insights) =>
  sum([
    insights?.likes,
    insights?.comments,
    insights?.saved,
    insights?.shares,
  ]) ?? null;

/** Returns true if CreatorInsights is empty or all values are 0
 * @param {creatorInsights.cities[]}
 * @param {creatorInsights.countries[]}
 * @param {creatorInsights.ages[]}
 * @param {creatorInsights.genders[]}
 * @param {creatorInsights.gendersPerAge[]}
 * @returns Bool
 */
export const isCreatorInsightsEmpty = (creatorInsights) => {
  const hasNonZero = (array) => array?.some((d) => d.value !== 0);

  if (isEmpty(creatorInsights)) {
    return true;
  }
  if ((creatorInsights?.countries?.length ?? 0) !== 0) {
    return false;
  }
  if ((creatorInsights?.cities?.length ?? 0) !== 0) {
    return false;
  }
  if (hasNonZero(creatorInsights.genders)) {
    return false;
  }
  if (hasNonZero(creatorInsights.ages)) {
    return false;
  }
  if (creatorInsights.gendersPerAge?.some((d) => hasNonZero(d.values))) {
    return false;
  }
  return true;
};

/** Returns true if tiktokInsights is empty or all values are 0
 * @param {insights.countries[]}
 * @param {insights.ages[]}
 * @param {insights.genders[]}
 * @returns Bool
 */
export const isTiktokInsightsEmpty = (insights) => {
  const hasNonZero = (array) => array?.some((d) => d.value !== 0);

  if (isEmpty(insights)) {
    return true;
  }
  if ((insights?.countries?.length ?? 0) !== 0) {
    return false;
  }
  if (hasNonZero(insights.genders)) {
    return false;
  }
  if (hasNonZero(insights.ages)) {
    return false;
  }
  return true;
};

export const isYoutubeInsightsEmpty = (insights) => {
  const hasNonZero = (array) => array?.some((d) => d.value !== 0);

  if (isEmpty(insights)) {
    return true;
  }
  if ((insights?.countries?.length ?? 0) !== 0) {
    return false;
  }
  if (hasNonZero(insights.genders)) {
    return false;
  }
  if (hasNonZero(insights.ages)) {
    return false;
  }
  return true;
};

export const mapInsightsToStats = (content, statsDef) => {
  const stats = [];

  statsDef.forEach((colDef) => {
    if (content && (content.hasOwnProperty(colDef.property) || colDef.calc)) {
      const value = colDef.calc
        ? colDef.calc(content)
        : content[colDef.property];

      const figure = colDef.formatter ? colDef.formatter(value) : value;

      stats.push({
        label: colDef.label,
        icon: colDef.icon,
        figure: figure,
      });
    }
  });

  return stats;
};

export const getLocationDisplay = (location) => {
  return (
    ([location?.city, location?.state, location?.country]
      .filter(Boolean)
      .join(', ') ||
      location?.continent) ??
    null
  );
};

/** prepends https:// to the string passed if it doesn't start with http:// or https://
 * leaves http:// untouched
 */
export function prependHttps(url) {
  if (/^http:\/\//.test(url)) {
    return url;
  }
  return url.replace(/https?:\/\//, '').replace(/^/, 'https://');
}

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren(children) {
  return React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
}

/** Returns mapped invitation status from actual status and graph status
 *
 * @param {String} invitationStatus the invitation status as returned from the API
 * @param {Bool}isGraphTokenValid Whether graph token is valid or not, as returned from the API
 * @param {Bool} shouldGraphReauth Whether user is forced to re-auth, as returned from the API
 */
export function getInvitationStatus(
  invitationStatus,
  isGraphTokenValid,
  shouldGraphReAuth,
  isFirstGraphAuth,
) {
  if (invitationStatus === 'revoked') {
    return MANAGER_INVITATION_STATUS.revoked;
  }

  if (invitationStatus === 'declined') {
    return MANAGER_INVITATION_STATUS.rejected;
  }

  if (invitationStatus === 'pending') {
    return MANAGER_INVITATION_STATUS.pending;
  }

  if (invitationStatus === 'accepted') {
    if (isFirstGraphAuth) {
      return MANAGER_INVITATION_STATUS.authPending;
    }

    if (shouldGraphReAuth) {
      return MANAGER_INVITATION_STATUS.authExpired;
    }

    if (isGraphTokenValid) {
      return MANAGER_INVITATION_STATUS.accepted;
    }

    return MANAGER_INVITATION_STATUS.authExpired;
  }

  throw new Error('unknown status');
}

const YOUTUBE_SCOPES = 'https://www.googleapis.com/auth/youtube.readonly';
const YOUTUBE_REDIRECT_URI = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/oauth/youtube&client_id=${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}`;

/** Opens Youtube auth popup */
export function openYoutubeAuth() {
  return window.open(
    `https://accounts.google.com/o/oauth2/v2/auth?scope=${YOUTUBE_SCOPES}&redirect_uri=${YOUTUBE_REDIRECT_URI}&access_type=offline&include_granted_scopes=true&response_type=code&prompt=consent`,
    'youtube-oauth',
    `width=600,height=600,top=${(window.innerHeight - 600) / 2},left=${
      (window.innerWidth - 600) / 2
    }`,
  );
}

export function getYoutubePermalink(ytVideoId) {
  return `https://www.youtube.com/watch?v=${ytVideoId}`;
}

export const TIKTOK_SCOPES = [
  'user.info.basic',
  'user.insights.creator',
  'video.list',
  'tcm.order.update',
  'biz.creator.insights',
  'biz.creator.info',
].join(',');

const TIKTOK_REDIRECT_URI = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/oauth/tiktok`;

const TIKTOK_AUTH_URL = `https://www.tiktok.com/v2/auth/authorize`;

/** Opens Tiktok auth popup */
export function openTiktokAuth() {
  const windowWidth = 724;
  const windowHeight = 948;

  return window.open(
    `${TIKTOK_AUTH_URL}?client_key=${process.env.NEXT_PUBLIC_TIKTOK_CLIENT_ID}&scope=${TIKTOK_SCOPES}&response_type=code&redirect_uri=${TIKTOK_REDIRECT_URI}`,
    'tiktok-oauth',
    `resizable,scrollbars,status,width=${windowWidth},height=${windowHeight},top=${
      (window.innerHeight - windowHeight) / 2
    },left=${(window.innerWidth - windowWidth) / 2}`,
  );
}

const SNAPCHAT_SCOPES = [
  'snapchat-profile-api',
  'snapchat-marketing-api',
  'snapchat-offline-conversions-api',
].join(' ');

const SNAPCHAT_REDIRECT_URI = `${process.env.NEXT_PUBLIC_APP_DOMAIN}/oauth/snapchat`;

export function openSnapchatAuth() {
  window.open(
    `https://accounts.snapchat.com/login/oauth2/authorize?client_id=${process.env.NEXT_PUBLIC_SNAPCHAT_CLIENT_ID}&redirect_uri=${SNAPCHAT_REDIRECT_URI}&response_type=code&scope=${SNAPCHAT_SCOPES}`,
    'snapchat-oauth',
    `width=600,height=600,top=${(window.innerHeight - 600) / 2},left=${
      (window.innerWidth - 600) / 2
    }`,
  );
}

/** Maps social platforms with their auth popup opener functions */
export const OPEN_AUTH_WINDOW = {
  /**
   * TODO: There should be an `openInstagramAuth` for consistence and to being
   * able to use this in a map, programatically, which is the way it's intended.
   */
  tiktok: openTiktokAuth,
  youtube: openYoutubeAuth,
};

/**
 * Function that returns the plain impersonating ID and isImpersonatingDisabled from Axios req
 * WARNING: Also mutates the req to delete the keys from params and data!
 * @param {Object} req Object Axios request object
 * @param {Object} [req.params] params if GET
 * @param {Object} [req.data] data if POST
 * @param {string} [req.params._impersonateOnce]
 * @param {boolean} [req.params._isImpersonatingDisabled]
 * @param {string} [req.data._impersonateOnce]
 * @param {boolean} [req.data._isImpersonatingDisabled]
 * @returns {{_isImpersonatingDisabled: boolean, _impersonateOnce: string}} */
export function getAndCleanImpersonatedParams(req) {
  const { _isImpersonatingDisabled } = req?.params || req?.data || {};
  let { _impersonateOnce } = req?.params || req?.data || {};

  delete req?.params?._isImpersonatingDisabled;
  delete req?.data?._isImpersonatingDisabled;
  delete req?.params?._impersonateOnce;
  delete req?.data?._impersonateOnce;

  return { _isImpersonatingDisabled, _impersonateOnce };
}

export const flattenResults = (pages) =>
  pages.reduce(
    (acc, page) => {
      acc.results = [...acc.results, ...page.results];
      acc.totalItems = page.totalItems;
      return acc;
    },
    { results: [], totalItems: 0 },
  );

/**
 *
 * @param {string} singular
 * @param {string} plural
 * @param {number} count
 */
export const pluralize = (singular, plural, count) => {
  return count === 1
    ? singular.replace(/%n/, count)
    : plural.replace(/%n/, count);
};

/**
 *
 * @param {Array<string>} array
 * @param {{ join?: string | undefined; wordFn?: (word: string) => string; hasHTML?: boolean; }} options
 * @returns
 */
export const enumerateAsText = (
  array,
  { join = 'and', wordFn, hasHTML = false } = {},
) => {
  if (!Array.isArray(array)) return;

  let arrayShallow = [...array];
  if (wordFn) {
    arrayShallow = arrayShallow.map(wordFn);
  }
  const last = arrayShallow.pop();
  const text = pluralize(
    `${last}`,
    `${arrayShallow.join(', ')} ${join} ${last}`,
    array.length,
  );

  if (hasHTML) {
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  }

  return text;
};

/**
 * @param {string} hex HEX color
 **/
export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * @param {string} hex HEX color
 * @param {number?} opacity HEX color
 * @returns {string} */
export const hexToRgbaString = (hex, opacity = 1) => {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r},${g},${b},${opacity})`;
};

/** Function that returns an array with a limited number of age ranges and adds the last ones
 * @param {Array} originalAgeRange Array for age ranges
 * @param {limit?} limit Number of ranges to group
 * @returns {array} */
export const getRegroupedAgeRange = (originalAgeRange, limit = 4) => {
  const maxSize = originalAgeRange.length;
  const summaryArray = originalAgeRange.slice(0, limit);
  const groupedArray = originalAgeRange.slice(limit, maxSize);
  const index = limit - 1;
  let extraPercentage = groupedArray.reduce(
    (total, currentValue) => total + currentValue.value,
    0,
  );

  summaryArray[index] = {
    label: `+${summaryArray[index].label.slice(0, 2)}`,
    value: summaryArray[index].value + extraPercentage,
  };

  return summaryArray;
};

/** Function that returns a string concatenated from an array of strings
 * @param {Array} arrayStr Array for age ranges
 * @param {string?} separator Number of ranges to group
 * @returns {string}
 */
export const concatenateStringsArray = (arrayStr, separator = ', ') =>
  arrayStr.filter((x) => typeof x === 'string' && x.length > 0).join(separator);

/**
 * Returns Instagram connection status
 * @param {import('./types').CreatorType} creator The creator object
 * @returns {SOCIAL_PLATFORMS_CONNECTION_STATUS} instagram connection status
 **/
export const getInstagramStatus = (creator) => {
  const { isFirstGraphAuth, isGraphTokenValid, shouldGraphReAuth } =
    getCreatorInstagramData(creator);

  if (isFirstGraphAuth) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.disconnected;
  }

  if (isGraphTokenValid && !shouldGraphReAuth) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connected;
  }

  if (shouldGraphReAuth || !isGraphTokenValid) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connectionExpired;
  }

  return SOCIAL_PLATFORMS_CONNECTION_STATUS.invalid;
};

/**
 * Returns Tiktok connection status
 * @param {import('./types').CreatorType} creator The creator object
 * @returns {SOCIAL_PLATFORMS_CONNECTION_STATUS} tiktok connection status
 **/
export const getTiktokStatus = (creator) => {
  const { isTiktokConnected, isTiktokTokenValid, shouldTiktokReAuth } =
    getCreatorTiktokData(creator);

  if (!isTiktokConnected) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.disconnected;
  }

  if (isTiktokTokenValid && !shouldTiktokReAuth) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connected;
  }

  if (!isTiktokTokenValid || shouldTiktokReAuth) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connectionExpired;
  }

  return SOCIAL_PLATFORMS_CONNECTION_STATUS.invalid;
};

/**
 * Returns Youtube connection status
 * @param {import('./types').CreatorType} creator The creator object
 * @returns {SOCIAL_PLATFORMS_CONNECTION_STATUS} youtube connection status
 **/
export const getYoutubeStatus = (creator) => {
  const { isYoutubeConnected, isYoutubeTokenValid, shouldYtReAuth } =
    getCreatorYoutubeData(creator);

  if (!isYoutubeConnected) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.disconnected;
  }

  if (isYoutubeTokenValid && !shouldYtReAuth) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connected;
  }

  if (!isYoutubeTokenValid || shouldYtReAuth) {
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connectionExpired;
  }

  const status = creator?.platforms?.youtube?.status;
  if (status === 'off-platform')
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.offPlatform;
  if (status === 'manual') return SOCIAL_PLATFORMS_CONNECTION_STATUS.manual;

  return SOCIAL_PLATFORMS_CONNECTION_STATUS.invalid;
};

// NOTE: This is temporary and marking it as deprecated such that it does not get used elsewhere
// @deprecated
export const getSnapchatStatus = (creator) => {
  const status = creator?.platforms?.snapchat?.status;

  if (status === 'connected')
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.connected;
  if (status === 'off-platform')
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.offPlatform;
  if (status === 'manual') return SOCIAL_PLATFORMS_CONNECTION_STATUS.manual;
  if (status === 'not-connected')
    return SOCIAL_PLATFORMS_CONNECTION_STATUS.disconnected;
  return SOCIAL_PLATFORMS_CONNECTION_STATUS.invalid;
};

/**
 * Returns Platforms connection status
 * @param {import('./types').CreatorType} creator The creator object
 * @returns {Record<SOCIAL_PLATFORMS | PARTIALLY_SUPPORTED_PLATFORMS, SOCIAL_PLATFORMS_CONNECTION_STATUS>} Connected platforms of a creator, otherwise an empty array.
 **/
export const getCreatorPlatformConnectionStatus = (creator) => {
  const instagramStatus = getInstagramStatus(creator);
  const tiktokStatus = getTiktokStatus(creator);
  const youtubeStatus = getYoutubeStatus(creator);
  const snapchatStatus = getSnapchatStatus(creator);

  return {
    [SOCIAL_PLATFORMS.instagram]: instagramStatus,
    [SOCIAL_PLATFORMS.tiktok]: tiktokStatus,
    [SOCIAL_PLATFORMS.youtube]: youtubeStatus,
    [PARTIALLY_SUPPORTED_PLATFORMS.snapchat]: snapchatStatus,
  };
};

/** Function that returns if two version tagging are equals
 * @param {string} version1 Version tagging
 * @param {string?} version2 Version tagging
 * @returns {boolean} if the major versions matchs */
export const areEqualMajorVersions = (version1, version2) => {
  if (!version1 || !version2) return false;

  const majorVersion1 = version1.split('.').shift();
  const majorVersion2 = version2.split('.').shift();

  return majorVersion1 === majorVersion2;
};

/** Function that returns the domain name from a URL
 * @param {string} url URL
 * @returns {string} domain name */
export const getDomainName = (url) => {
  const domain = url.match(/(\w+)\.\w{2,}(\/|\?|$)/);
  return domain ? domain[1] : '';
};
