import { useBreakpointValue as useBreakpointValueChakra } from '@chakra-ui/react';
import { isNil } from 'lodash';

import authApi from './authApi';
import { SOCIAL_PLATFORMS } from './constants';
import { generatePath } from './core/router/router.helpers';
import { getCreatorInstagramData, getCreatorTiktokData } from './creatorUtils';
import { FEATURE_STATUS } from './hooks/useFeatureFlag';
import { CreatorType, YoutubeChannelType } from './types';

export const getCreatorHandle = (creator: CreatorType): string | undefined =>
  creator.platforms?.instagram?.handle;

export const getUrlSearchParams = (): URLSearchParams =>
  new URLSearchParams(window.location.search);

export const urlSearchParamsIncludes = (arr: Array<string>): boolean =>
  arr.every((val: string) =>
    Array.from(getUrlSearchParams().keys()).includes(val),
  );

export const getInstagramProfileUrl = (handle: string): string =>
  `https://www.instagram.com/${handle}`;

export const getTiktokProfileUrl = (handle: string): string =>
  `https://www.tiktok.com/@${handle}`;

export const getYoutubeChannelUrl = (channelId: string): string =>
  `https://www.youtube.com/channel/${channelId}`;

export const getPlatformUrl = (
  platform: SOCIAL_PLATFORMS,
  creator: CreatorType,
  ytChannel: YoutubeChannelType,
) => {
  const { handle } = getCreatorInstagramData(creator);
  const { tiktokHandle } = getCreatorTiktokData(creator);

  const mapSocialPlatformNameToPlatformHandleProperty = {
    [SOCIAL_PLATFORMS.instagram]: getInstagramProfileUrl(handle),
    [SOCIAL_PLATFORMS.tiktok]: getTiktokProfileUrl(tiktokHandle),
    [SOCIAL_PLATFORMS.youtube]: getYoutubeChannelUrl(
      ytChannel.ytChannelId ?? '',
    ),
  };

  return mapSocialPlatformNameToPlatformHandleProperty[platform];
};

export const formatYoutubeVideoUrl = (videoId: string) =>
  `https://www.youtube.com/watch?v=${videoId}`;

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getEchoLink = (
  href: string,
  creatorId?: string,
): string | void => {
  const accessToken = authApi.jwtAccessToken?.accessToken;
  const refreshToken = authApi.jwtAccessToken?.refreshToken;

  if (accessToken && refreshToken) {
    return generatePath(href, {
      query: {
        accessToken,
        refreshToken,
        creatorId,
      },
    });
  }
};

export const getMaxValue = (value: number, maxValue: number = 99): string => {
  if (value > maxValue) {
    return `+${maxValue}`;
  }
  return value.toString();
};

export const isSSR = typeof window === 'undefined';

export const convertPxToRems = (pixels: number): string | undefined => {
  if (!isSSR) {
    if (!isNil(pixels)) {
      const baseFontSizePixelsString = window
        .getComputedStyle(document.body)
        .getPropertyValue('font-size');
      const baseFontSizeInPx = parseFloat(
        baseFontSizePixelsString.replace('px', ''),
      );

      const rems = pixels / baseFontSizeInPx;

      return `${rems}rem`;
    }
  }

  return;
};

const convertImageSrcToPDFValidSrc = (src: string): Promise<string> => {
  const image = new Image();
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    image.onload = () => {
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      canvasContext?.drawImage(image, 0, 0);
      resolve(canvas.toDataURL('image/jpeg', 1.0));
    };
    image.onerror = reject;
    image.crossOrigin = '*';
    image.src = src;
  });
};

export const getValidPdfImageSrc = async (
  imageSrc?: string,
): Promise<string | undefined> => {
  if (!imageSrc) {
    return;
  }
  try {
    if (imageSrc && !/\S+(\.(jpe?g|png))$/.test(imageSrc)) {
      const convertedImageSrc = await convertImageSrcToPDFValidSrc(imageSrc);
      return convertedImageSrc;
    }
  } catch {
    return imageSrc;
  }
};

export const useBreakpointValue = (values: any) =>
  useBreakpointValueChakra(values, { ssr: false });

export const getRandomIntFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * max) + min;

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%@_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const closest = (goal: number, arr: number[]) => {
  if (arr.length) {
    return arr.reduce((prev, curr) => {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
    });
  }
};

export const getTalentTitle = (savedListRosterFlag: FEATURE_STATUS) => {
  if (savedListRosterFlag === 'enabled') return 'My Talent Connections';

  return 'My Talent';
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[a-z0-9._%+-]+(?<!\.)@[a-z0-9.-]+\.[a-z]{2,24}$/;
  return emailRegex.test(email);
};
