export const HANDLE_PREFIX = '@';

export enum SOCIAL_PLATFORMS_CONNECTION_STATUS {
  connected = 'connected',
  connectionExpired = 'connectionExpired',
  disconnected = 'disconnected',
  invalid = 'invalid',
  loading = 'loading',
  offPlatform = 'off-platform',
  manual = 'manual',
}

export enum SOCIAL_PLATFORMS {
  instagram = 'instagram',
  tiktok = 'tiktok',
  youtube = 'youtube',
  // snapchat = 'snapchat',
}

export enum PARTIALLY_SUPPORTED_PLATFORMS {
  snapchat = 'snapchat',
}

export const ALL_SOCIAL_PLATFORMS = {
  ...SOCIAL_PLATFORMS,
  ...PARTIALLY_SUPPORTED_PLATFORMS,
};
export type ALL_SOCIAL_PLATFORM_TYPE =
  (typeof ALL_SOCIAL_PLATFORMS)[keyof typeof ALL_SOCIAL_PLATFORMS];

export enum SOCIAL_PLATFORMS_NAMES {
  instagram = 'Instagram',
  facebook = 'Facebook',
  youtube = 'YouTube',
  tiktok = 'TikTok',
  twitter = 'Twitter',
  snapchat = 'Snapchat',
}
export type SOCIAL_PLATFORM_SHORT = 'IG' | 'TT' | 'YT' | 'SC';

const INSTAGRAM_STORY_CONTENT_TYPE = 'story';
const INSTAGRAM_POST_CONTENT_TYPE = 'post';
const TIKTOK_CONTENT_TYPE = 'tiktok';
const YOUTUBE_CONTENT_TYPE = 'youtube';

export enum INSTAGRAM_CONTENT_TYPES {
  story = 'story',
  post = 'post',
  reels = 'reels',
}

export enum INSTAGRAM_CONTENT_TYPES_NAMES {
  story = 'Story',
  post = 'Post',
  reels = 'Reels',
}

export const PLATFORM_CONTENT_TYPE = {
  [INSTAGRAM_STORY_CONTENT_TYPE]: SOCIAL_PLATFORMS.instagram,
  [INSTAGRAM_POST_CONTENT_TYPE]: SOCIAL_PLATFORMS.instagram,
  [TIKTOK_CONTENT_TYPE]: SOCIAL_PLATFORMS.tiktok,
  [YOUTUBE_CONTENT_TYPE]: SOCIAL_PLATFORMS.youtube,
};

/**
 * TODO: At the moment `SOCIAL_PLATFORMS_ENABLED_FOR_MEDIAPACKS` is a fixed
 * constant, but the idea is to have an `useEnabledSocialPlatforms` hook if
 * we start using feature flags, so this would just return an array of keys
 * of the SOCIAL_PLATFORMS currently globally enabled
 * (for mediapacks or whatever) in the running environment.
 */
export const SOCIAL_PLATFORMS_ENABLED_FOR_MEDIAPACKS = [
  SOCIAL_PLATFORMS.instagram,
  SOCIAL_PLATFORMS.tiktok,
  SOCIAL_PLATFORMS.youtube,
];

export const CONTENT_TYPES = {
  ...INSTAGRAM_CONTENT_TYPES,
  [TIKTOK_CONTENT_TYPE]: TIKTOK_CONTENT_TYPE,
  [YOUTUBE_CONTENT_TYPE]: YOUTUBE_CONTENT_TYPE,
} as const;

export const PRIMARY_SOCIAL_PLATFORMS = [
  SOCIAL_PLATFORMS.instagram,
  SOCIAL_PLATFORMS.tiktok,
] as const;

export const SOCIAL_PLATFORMS_REMINDER_DAYS: number = 3;
