import { ALL_SOCIAL_PLATFORMS, SOCIAL_PLATFORMS } from '~/constants';
import { ISearchContentResult, SearchApiCreator } from '~/searchApi';
import * as viewModel from '~/types';
import { CoManager, CreatorType, ManagerAgency } from '~/types';
import * as notificationsViewModel from '~/types/notification';

import * as clientApiViewModel from './clientApi.types';
import * as clientApiCreatorModel from './creator.model';
import { Creator } from './creator.model';
import * as clientApiNotificationModel from './notifications.model';

export const mapCreatorPlatformInstagramFromApiModelToViewModel = ({
  followersCount,
  graphAuthReminderSentAt,
  graphAuthReminderSentCount,
  handle,
  higher,
  isFirstGraphAuth,
  isGraphTokenValid,
  name,
  profilePicUrl,
  shouldGraphReAuth,
  status,
}: clientApiCreatorModel.CreatorPlatformInstagram): clientApiViewModel.ICreatorPlatformStatus => ({
  avatar: profilePicUrl,
  followersCount,
  handle,
  higher,
  isConnected: !isFirstGraphAuth,
  isTokenValid: isGraphTokenValid,
  name,
  reminderSentAt: graphAuthReminderSentAt,
  reminderSentCount: graphAuthReminderSentCount,
  shouldReAuth: shouldGraphReAuth,
  status,
});

export const mapCreatorPlatformTiktokFromApiModelToViewModel = ({
  followersCount,
  higher,
  isTiktokConnected,
  isTiktokTokenValid,
  name,
  profileImage,
  shouldTiktokReAuth,
  tiktokAuthReminderSentAt,
  tiktokAuthReminderSentCount,
  tiktokHandle,
  status,
}: clientApiCreatorModel.CreatorPlatformTiktok): clientApiViewModel.ICreatorPlatformStatus => ({
  avatar: profileImage,
  followersCount,
  handle: tiktokHandle,
  higher,
  isConnected: isTiktokConnected,
  isTokenValid: isTiktokTokenValid,
  name,
  reminderSentAt: tiktokAuthReminderSentAt,
  reminderSentCount: tiktokAuthReminderSentCount,
  shouldReAuth: shouldTiktokReAuth,
  status,
});

export const mapCreatorPlatformYoutubeFromApiModelToViewModel = ({
  higher,
  handle,
  profileImage,
  isYoutubeConnected,
  isYoutubeTokenValid,
  shouldYtReAuth,
  subscriberCount,
  ytAuthReminderSentAt,
  ytAuthReminderSentCount = 0,
  channelName,
  status,
}: clientApiCreatorModel.CreatorPlatformYoutube): clientApiViewModel.ICreatorPlatformStatus => ({
  avatar: profileImage,
  followersCount: subscriberCount,
  higher,
  handle: handle,
  isConnected: isYoutubeConnected,
  isTokenValid: isYoutubeTokenValid,
  reminderSentAt: ytAuthReminderSentAt,
  reminderSentCount: ytAuthReminderSentCount,
  shouldReAuth: shouldYtReAuth,
  name: channelName,
  status,
});

export const mapCreatorPlatformSnapchatFromApiModelToViewModel = ({
  followersCount,
  snapUserName,
  displayName,
  profileUrl,
  isSnapchatConnected,
  isSnapchatTokenValid,
  snapchatAuthReminderSentAt,
  snapchatAuthReminderSentCount,
  shouldSnapchatReAuth,
  status,
}: clientApiCreatorModel.CreatorPlatformSnapchat): clientApiViewModel.ICreatorPlatformStatus => ({
  followersCount: followersCount,
  handle: snapUserName,
  avatar: profileUrl,
  isConnected: isSnapchatConnected,
  isTokenValid: isSnapchatTokenValid,
  name: displayName,
  reminderSentAt: snapchatAuthReminderSentAt,
  reminderSentCount: snapchatAuthReminderSentCount,
  shouldReAuth: shouldSnapchatReAuth,
  status,
});

export const mapCreatorPlatformsFromApiModelToViewModel = ({
  platforms,
}: clientApiCreatorModel.CreatorPlatforms): clientApiViewModel.CreatorPlatformsType => ({
  [SOCIAL_PLATFORMS.instagram]:
    platforms.instagram &&
    mapCreatorPlatformInstagramFromApiModelToViewModel(platforms.instagram),
  [SOCIAL_PLATFORMS.tiktok]:
    platforms.tiktok &&
    mapCreatorPlatformTiktokFromApiModelToViewModel(platforms.tiktok),
  [SOCIAL_PLATFORMS.youtube]:
    platforms.youtube &&
    mapCreatorPlatformYoutubeFromApiModelToViewModel(platforms.youtube),
  [ALL_SOCIAL_PLATFORMS.snapchat]:
    platforms.snapchat &&
    mapCreatorPlatformSnapchatFromApiModelToViewModel(platforms.snapchat),
});

export const mapCreatorAgencyContentFromApiModelToViewModel = (
  content: clientApiCreatorModel.CreatorAgencyContentSelected,
): ISearchContentResult => ({
  ...(content as any),
  contentId: content.id,
});

export const mapCreatorComanagersFromApiModelToViewModel = (
  coManagers: viewModel.CoManager | undefined,
): {
  id: string;
  name: string;
  email: string;
  agency: ManagerAgency;
}[] => {
  if (!coManagers) {
    return [];
  }

  return Object.values(coManagers).map((comanager) => {
    // added email as for the feat. [FOAM-6912] assign a Creator to a Manager with a pending invitation
    return {
      id: comanager.coManager.id,
      name: comanager.coManager.name,
      email: comanager.coManager.email,
      agency: comanager.coManager.agency,
    };
  });
};

const calculateAge = (dateOfBirth: string): number => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = today.getFullYear() - birthDate.getFullYear();

  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const mapCreatorFromSearchApiModelToFoamApiModel = ({
  creator: {
    date_of_birth,
    managers,
    benchmarks,
    bio_agency,
    platforms,
    category_agency,
    location,
    created_at,
    updated_at,
    email,
    first_name,
    gender_agency,
    hasConnectedPlatform,
    id,
    demographics,
    slug,
    name,
    is_off_platform,
    total_audience,
    image,
    content_agency_selected,
    echo_web_status,
    echo_welcome_seen,
    highlighted_content,
    last_activity,
    last_login,
    last_name,
    notes,
    platform_full_access,
  },
  caller: { id: callerId },
}: {
  creator: SearchApiCreator;
  caller: { id: string };
}): Creator => {
  const age = !date_of_birth ? undefined : calculateAge(date_of_birth);
  const owner = managers.find((manager) => manager.owner);
  const callerManager = managers.find((manager) => manager.id === callerId);
  const belongsToSameAgency =
    owner?.agency_id === callerManager?.agency_id &&
    callerManager?.agency_id !== undefined;
  let coManagers: CoManager = {};
  managers
    .filter((manager) => !manager.owner)
    .forEach((manager, index) => {
      coManagers[index] = {
        coManager: {
          id: manager.id,
          name: manager.agency_name ?? '',
        },
      };
    });

  return {
    age,
    agency: owner?.agency_name
      ? {
          id: owner.agency_id ? owner.agency_id : undefined,
          name: owner?.agency_name,
        }
      : undefined,
    belongsToSameAgency,
    benchmark: benchmarks,
    bioAgency: bio_agency ? bio_agency : undefined,
    biography: platforms?.instagram?.biography ?? '',
    categoryAgency: category_agency.join(','),
    city: location?.city ? location?.city : undefined,
    coManagerObjectId: callerManager?.id,
    contentAgencySelected: content_agency_selected,
    createdAt: created_at,
    dateOfBirth: date_of_birth,
    echoWebStatus: echo_web_status ?? null,
    echoWelcomeSeen: echo_welcome_seen ?? true,
    email: email ?? '',
    firstName: first_name ? first_name : undefined,
    followersCount: platforms?.instagram?.followers_count ?? 0,
    followsCount: platforms?.instagram?.follows_count ?? 0,
    genderAgency: gender_agency ? gender_agency : undefined,
    hasConnectedPlatform,
    highlightedContent: highlighted_content ?? [],
    id,
    insight: demographics?.instagram ? demographics?.instagram : undefined,
    isCoManager: !callerManager?.owner,
    isFirstGraphAuth: platforms?.instagram?.is_first_graph_auth ?? false,
    isGraphTokenValid: platforms?.instagram?.is_graph_token_valid ?? false,
    isManagerOwner: !!callerManager?.owner,
    isTiktokConnected: platforms?.tiktok?.connected ?? false,
    isTiktokTokenValid: platforms?.tiktok?.is_tiktok_token_valid ?? false,
    isYoutubeConnected: platforms?.youtube?.connected ?? false,
    isYoutubeTokenValid: platforms?.youtube?.is_youtube_token_valid ?? false,
    isOffPlatform: is_off_platform ?? false,
    lastActivity: last_activity ?? '',
    lastLogin: last_login ?? '',
    lastName: last_name ?? '',
    location: location
      ? {
          address1: location?.address_1 ? location?.address_1 : undefined,
          address2: location?.address_2 ? location?.address_2 : undefined,
          city: location?.city ? location?.city : undefined,
          continent: location?.continent ? location?.continent : undefined,
          country: location?.country ? location?.country : undefined,
          postCode: location?.post_code ? location?.post_code : undefined,
        }
      : undefined,
    // @ts-ignore - If more data about managers is needed, the FE should fetch it separately
    manager: {
      id: owner?.id ?? '',
      agencyName: owner?.agency_name ?? '',
    },
    mediaCount: platforms?.instagram?.media_count ?? 0,
    name: name ?? '',
    notes: notes,
    platformFullAccess: platform_full_access,
    shouldGraphReAuth: platforms?.instagram?.should_graph_re_auth ?? false,
    shouldTiktokReAuth: platforms?.tiktok?.should_tiktok_re_auth ?? false,
    shouldYtReAuth: platforms?.youtube?.should_yt_re_auth ?? false,
    slug: slug ? slug : undefined,
    tiktokInsight: demographics?.tiktok ? demographics?.tiktok : undefined,
    totalAudience: total_audience ?? 0,
    updatedAt: updated_at ?? '',
    ytChannels: platforms?.youtube?.yt_channel_id
      ? [
          {
            id: platforms?.youtube?.yt_channel_id,
          },
        ]
      : [],
    profilePicUrl: image ?? '',
    coManagers: coManagers,
    ytTokens: [], // I did not find any usage of this. Let's deprecate it going forward!
    updatedAtAgo: '', // I did not find any usage of this. Let's deprecate it going forward!
    createdAtAgo: '', // I did not find any usage of this. Let's deprecate it going forward!
  };
};

export const mapCreatorFromApiModelToViewModel = ({
  age,
  agency,
  avatar,
  belongsToSameAgency,
  benchmark,
  bioAgency,
  biography,
  categoryAgency,
  city,
  contentAgencySelected,
  dateOfBirth,
  email,
  firstName,
  fullName,
  genderAgency,
  highlightedContent,
  id,
  hasConnectedPlatform,
  isCoManager,
  isManagerOwner,
  isOnCampaign,
  lastName,
  location,
  manager,
  notes,
  platforms,
  slug,
  tiktokInsight,
  totalAudience,
  ytChannels,
  ytTokens,
  echoWebStatus,
  echoWelcomeSeen,
  createdAt,
  profilePicUrl,
  name,
  coManagers,
  isOffPlatform,
  webLinks,
  platformFullAccess,
  inviteStatus,
  invitations,
}: clientApiCreatorModel.Creator): viewModel.ICreator => ({
  age,
  agency,
  avatar,
  belongsToSameAgency,
  benchmark,
  biography,
  bioAgency,
  categoryAgency,
  email,
  echoWebStatus,
  echoWelcomeSeen,
  dateOfBirth,
  genderAgency,
  firstName,
  fullName,
  id,
  lastName,
  location,
  city,
  manager,
  notes,
  platforms: mapCreatorPlatformsFromApiModelToViewModel({ platforms }),
  hasConnectedPlatform,
  isCoManager,
  isManagerOwner,
  isOnCampaign,
  slug,
  tiktokInsight,
  totalAudience,
  ytChannels,
  ytTokens,
  highlightedContent: highlightedContent?.map(
    mapCreatorAgencyContentFromApiModelToViewModel,
  ),
  contentAgencySelected: contentAgencySelected?.map(
    mapCreatorAgencyContentFromApiModelToViewModel,
  ),
  createdAt,
  profilePicUrl,
  name,
  coManagers: mapCreatorComanagersFromApiModelToViewModel(coManagers),
  isOffPlatform,
  webLinks,
  platformFullAccess,
  inviteStatus,
  invitations,
});

export const mapIcreatorToCreatorType = (
  creator: viewModel.ICreator,
  //@ts-ignore
): CreatorType => ({
  ...creator,
  age: creator.age,
  agency: {
    name: creator.manager?.name ?? '',
    profilePicUrl: creator.manager?.agencyLogo,
  },
  email: creator.email,
  benchmark: creator.benchmark,
  firstName: creator.firstName,
  lastName: creator.lastName,
  slug: creator.slug,
  biography: creator.biography,
  coManagerObjectId: creator.coManagerObjectId,
  echoWebStatus: creator.echoWebStatus,
  echoWelcomeSeen: creator.echoWelcomeSeen,
  platforms: {
    instagram: {
      profilePicUrl: creator.platforms.instagram?.avatar ?? '',
      name: creator.platforms.instagram?.name ?? '',
      isFirstGraphAuth: !creator.platforms.instagram?.isConnected,
      isGraphTokenValid: !!creator.platforms.instagram?.isTokenValid,
      shouldGraphReAuth: !!creator.platforms.instagram?.shouldReAuth,
      graphAuthReminderSentAt: creator.platforms.instagram?.reminderSentAt,
      graphAuthReminderSentCount:
        creator.platforms.instagram?.reminderSentCount ?? 0,
      handle: creator.platforms.instagram?.handle ?? '',
      followersCount: creator.platforms.instagram?.followersCount ?? 0,
      followsCount: creator.platforms.instagram?.followersCount ?? 0,
      engagementRate: 0,
      mediaCount: 0,
    },
    tiktok: {
      name: creator.platforms.tiktok?.name ?? '',
      isTiktokConnected: !!creator.platforms.tiktok?.isConnected,
      isTiktokTokenValid: !!creator.platforms.tiktok?.isTokenValid,
      profileImage: creator.platforms.tiktok?.avatar ?? '',
      shouldTiktokReAuth: !!creator.platforms.tiktok?.shouldReAuth,
      tiktokAuthReminderSentCount:
        creator.platforms.tiktok?.reminderSentCount ?? 0,
      tiktokAuthReminderSentAt: creator.platforms.tiktok?.reminderSentAt,
      tiktokHandle: creator.platforms.tiktok?.handle ?? '',
    },
    youtube: {
      channelName: creator.platforms.youtube?.name ?? '',
      isYoutubeConnected: !!creator.platforms.youtube?.isConnected,
      isYoutubeTokenValid: !!creator.platforms.youtube?.isTokenValid,
      shouldYtReAuth: !!creator.platforms.youtube?.shouldReAuth,
      subscriberCount: creator.platforms.youtube?.followersCount ?? 0,
      ytAuthReminderSentCount:
        creator.platforms.youtube?.reminderSentCount ?? 0,
      followersCount: creator.platforms.youtube?.followersCount ?? 0,
      ytAuthReminderSentAt: creator.platforms.youtube?.reminderSentAt,
    },
  },
});

export const mapCreatorsFromApiModelToViewModel = (
  creators: clientApiCreatorModel.Creator[],
): viewModel.ICreator[] => creators.map(mapCreatorFromApiModelToViewModel);

export const mapNotificationsFromApiModelToViewModel = (
  notifications: clientApiNotificationModel.INotificationType[],
): notificationsViewModel.ISupportedNotificationType[] =>
  notifications.map(
    ({ read, ...notification }) =>
      ({
        ...notification,
        isRead: read,
        params: {
          ...notification.params,
          creator: mapCreatorFromApiModelToViewModel(
            notification.params.creator as clientApiCreatorModel.Creator,
          ),
        },
      }) as any,
  );
