import { ApiModule } from '~/clientApi/ApiModule';

const MODULE = 'tiktok-contents';

const extraEndpoints = {
  share: `POST ${MODULE}/:id/share`,
  removePassword: `DELETE ${MODULE}/:id/share/password`,
};

const ttContents = (apiInstance) =>
  new ApiModule(apiInstance, MODULE, extraEndpoints);

export default ttContents;
