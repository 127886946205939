import { useFlagStore } from '~/components/FeatureFlagControls/store';
import { FEATURE_FLAGS } from '~/constants';

export const useFeature = (flag: FEATURE_FLAGS) => {
  const { flags, status } = useFlagStore();

  return {
    isEnabled: Boolean(flags[flag]?.value),
    status,
  };
};
