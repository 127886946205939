export enum FEATURE_FLAGS {
  foamAdsFilter = 'foam_ads_filter',
  foamAdvancedFilters = 'foam_advanced_filters',
  foamAgencyManagement = 'foam_agency_management',
  foamAgencySettingAddManagerWithoutEmail = 'foam_agency_settings_add_manager_without_email',
  foamAgencySettingsManagerRoleCanRemoveCreator = 'foam_agency_settings_manager_role_can_remove_creator',
  foamAgencySettingsTalentSection = 'foam_agency_settings_talent_section',
  foamAgencyManagementCoManagerPermissions = 'foam_agency_management_comanager_permissions',
  foamAiBrandKeywords = 'ai_brand_keywords',
  foamCampaign = 'foam_rfp_gmail',
  foamChromePushNotifications = 'foam_chrome_push_notifications',
  foamContentSearch = 'foam_content_search',
  foamContentSearchWildcardNotification = 'foam_content_search_wildcard_notification',
  foamContentSearchPostedAtTimeframeFilter = 'foam_content_search_posted_at_timeframe_filter',
  foamCreatorListingPage = 'foam_creator_listing_page',
  foamCreatorProfile = 'foam_creator_profile',
  foamDemoContentSearchAutocomplete = 'foam_demo_content_search_autocomplete',
  foamContentSearchPostedAtColumn = 'foam_content_search_posted_at_column',
  foamEmailAssistant = 'foam_email_assistant',
  foamHeapDev = 'foam_heap_dev',
  foamManagedTalentContentSearch = 'foam_content_search_managed_talent',
  foamMyAgencyContentPicker = 'foam_my_agency_content_picker',
  foamNotificationSettings = 'foam_notification_settings',
  foamOffPlatform = 'foam_off_platform',
  foamProfileSettings = 'foam_profile_settings',
  foamReels = 'foam_reels',
  foamReelsMediaPack = 'foam_reels_media_pack',
  foamRosterHideFilters = 'foam_roster_hide_filters',
  foamRosterTalentSearch = 'foam_roster_talent_search',
  foamSavedListAgencies = 'foam_saved_lists_agencies',
  foamSavedLists = 'foam_saved_lists',
  foamSavedListSave = 'foam_saved_list_save',
  foamStackedView = 'foam_stacked_view',
  foamTalentFilters = 'foam_talent_filters',
  foamOffPlatformAddSocials = 'foam_off_platform_add_socials',
  foamOffPlatformRemoveSocials = 'foam_off_platform_remove_socials',
  foamOffPlatformYoutube = 'foam_off_platform_youtube',
  foamSharedActivity = 'foam_shared_activity',
  foamMagicProfiles = 'foam_magic_profiles',
  snapchatAuth = 'foam_snapchat_authentication',
  foamSavedListsRoster = 'foam_saved_lists_roster',
  foamGlobalSearch = 'foam_global_search',
  foamOffPlatformRosterImport = 'foam_off_platform_roster_import',
  foamSavedListsPin = 'foam_saved_lists_pin',
  foamCollapsedView = 'foam_collapsed_view',
  foamContentMetricsAlignment = 'foam_content_metrics_alignment',
  foamEmbedsAlignment = 'foam_embeds_alignment',
  foamWebLinks = 'foam_web_links',
  foamUnverifiedToVerified = 'foam_unverified_to_verified',
  foamManagerContactShared = 'foam_manager_contact_shared',
  foamMediaPackContentLinksUpdate = 'foam_media_pack_content_links_update',
  foamAgencySettingsManagerChangePrimary = 'foam_agency_settings_manager_change_primary',
  foamTalentRecordAudienceDemo = 'foam_talent_record_audience_demo',
  foamAddTalent = 'foam_add_talent',
  foamAgencySettingsTalentRevamp = 'foam_agency_settings_talent_revamp',
  foamAgencySettingsDisplayExternalData = 'foam_agency_settings_display_external_data',
  foamSmartCreatorProfiles = 'foam_smart_creator_profiles',
  foamSharedTalentProfile = 'foam_shared_talent_profile',
  foamTalentEmptyState = 'foam_talent_empty_state',
  foamTalentLocation = 'foam_talent_location',
  foamFeatureFlagController = 'foam_feature_flag_controller',
  foamContentProfileLink = 'foam_content_profile_link',
  foamContentSearchEmptyState = 'foam_content_search_empty_state',
  foamWeeklyReportToggle = 'foam_weekly_report_toggle',
  foamSavedListsEmptyState = 'foam_saved_lists_empty_state',
  foamTalentRecordChannelMetricsExport = 'foam_talent_record_channel_metrics_export',
  foamTestingFlag = 'foam_testing_flag',
}
