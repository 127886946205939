import type { UseToastOptions } from '@chakra-ui/react';

export * from './auth';
export * from './brandDeals';
export * from './common';
export * from './content';
export * from './extension';
export * from './featureFlags';
export * from './login';
export * from './metrics';
export * from './notifications';
export * from './platforms';
export * from './signUp';
// JS constants
export * from './headers';
export * from './localStorage';
export * from './mediaPack';
export * from './pdf';
export * from './countries';
export * from './savedLists';

export const MOBILE_MIN_WIDTH = 480;
export const TABLET_MIN_WIDTH = 768;
export const DESKTOP_MIN_WIDTH = 992;
export const LARGE_DESKTOP_MIN_WIDTH = 1280;

export enum MEDIA_TYPES {
  album = 'album',
  image = 'image',
  video = 'video',
}

export const STORY_ASPECT_RATIO = 9 / 16;
export const YT_ASPECT_RATIO = 16 / 9;
export const TIKTOK_PREVIEW_ASPECT_RATIO = 0.73;

export const LONG_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};
export const LONG_DATETIME_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  month: 'long',
  year: 'numeric',
};
export const SHORT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
export const MONTH_NAME_DAY_YEAR_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};
export const SHORT_DATE_FORMAT_SLASH: Intl.DateTimeFormatOptions =
  SHORT_DATE_FORMAT;

export const FOAM_SITE_URL = 'https://www.usefoam.com/';
export const CONTACT_EMAIL = 'hello@foam.io';
export const SUGGESTIONS_FORM_URL = 'https://whalar.link/feedback';

export const GOOGLE_FAVICONS_API_URL = 'https://www.google.com/s2/favicons';

export const TOAST_ERROR_GENERIC: UseToastOptions = {
  title: 'Oops!',
  description: 'There has been an error. Please try again',
  status: 'error',
  duration: 9000,
  isClosable: true,
};

export const USER_ALREADY_EXISTS_ERROR =
  'An account already exists with this email address';

export const PIXEL_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const LOGO_FALLBACK = '/images/logo-fallback.png';

export const USER_CONTENT_TYPES = {
  images: 'Images',
  story: 'Story',
  shortVideo: 'Short Form Video',
  longVideo: 'Long Form Video (IGTV)',
  stopmotion: 'Stop-motion Video',
  cinemagraph: 'Cinemagraph',
};

export const CURRENCIES = {
  USD: { code: 'USD', symbol: '$', name: 'United States dollar' },
  EUR: { code: 'EUR', symbol: '€', name: 'Euro' },
  GBP: { code: 'GBP', symbol: '£', name: 'Pound sterling' },
  AUD: { code: 'AUD', symbol: '$', name: 'Australian dollar' },
  BRL: { code: 'BRL', symbol: '$', name: 'Brazilian real' },
  CAD: { code: 'CAD', symbol: '$', name: 'Canadian dollar' },
  HKD: { code: 'HKD', symbol: '$', name: 'Hong Kong dollar' },
  INR: { code: 'INR', symbol: '₹', name: 'Indian rupee' },
  JPY: { code: 'JPY', symbol: '¥', name: 'Japanese yen' },
  MXN: { code: 'MXN', symbol: '$', name: 'Mexican peso' },
  NZD: { code: 'NZD', symbol: '$', name: 'New Zealand dollar' },
  NOK: { code: 'NOK', symbol: 'kr', name: 'Norwegian krone' },
  CNY: { code: 'CNY', symbol: '元', name: 'Renminbi' },
  RUB: { code: 'RUB', symbol: '₽', name: 'Russian ruble' },
  SGD: { code: 'SGD', symbol: '$', name: 'Singapore dollar' },
  ZAR: { code: 'ZAR', symbol: 'R', name: 'South African rand' },
  KRW: { code: 'KRW', symbol: '₩', name: 'South Korean won' },
  SEK: { code: 'SEK', symbol: 'kr', name: 'Swedish krona' },
  CHF: { code: 'CHF', symbol: 'CHF', name: 'Swiss franc' },
  TRY: { code: 'TRY', symbol: '₺', name: 'Turkish lira' },
};

export enum ORIGIN {
  foam = 'foam',
  echo = 'echo',
}

export enum USER_ROLES {
  anonymous = 'anonymous',
  creator = 'foam-creator',
  echoCreator = 'echo-creator',
  managed = 'foam-managed-creator',
  talentManager = 'foam-manager',
}

export enum USER_ROLES_API_MGMNT {
  agencyAdmin = 'agency_admin',
  agencyManager = 'agency_manager',
  agencyTeam = 'agency_team',
}

export const USER_ROLES_FRIENDLY_API_MGMNT: Record<string, string> = {
  agency_admin: 'Admin',
  agency_manager: 'Manager',
  agency_team: 'Team',
};

export enum USER_PERMISSIONS {
  impersonation = 'impersonation',
}

export const INVITATIONS_STATUS = {
  accepted: 'accepted',
  declined: 'declined',
  pending: 'pending',
  revoked: 'revoked',
  // TODO: ADD REST STATUS
};

export const MAX_MANAGER_INVITATIONS = 100;

export const MAX_CREATOR_WEBLINKS = 4;

// MAIN URLS
export const ROOT_URL = '/';

export const LOGOUT_URL = '/logout';
export const MANAGER_MAIN_URL = '/dashboard';
export const ROSTER_URL = '/roster';

export const HASHTAGS_REGEX = /(#\w+)/g;
export const HANDLES_REGEX = /(@[A-Za-z0-9._]+)/g;
// lifted from https://stackoverflow.com/a/17773849
export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

// TODO: refactor to an enum & refactor each component using it to TS (a few)
// Invitations statuses
const MANAGER_INVITATION_STATUS_REVOKED = 'revoked';
const MANAGER_INVITATION_STATUS_REJECTED = 'rejected';
const MANAGER_INVITATION_STATUS_PENDING = 'pending';
const MANAGER_INVITATION_STATUS_ACCEPTED = 'accepted';
const MANAGER_INVITATION_STATUS_AUTH_EXPIRED = 'authExpired';
const MANAGER_INVITATION_STATUS_AUTH_PENDING = 'authPending';
export const MANAGER_INVITATION_STATUS = {
  revoked: MANAGER_INVITATION_STATUS_REVOKED,
  rejected: MANAGER_INVITATION_STATUS_REJECTED,
  pending: MANAGER_INVITATION_STATUS_PENDING,
  accepted: MANAGER_INVITATION_STATUS_ACCEPTED,
  authExpired: MANAGER_INVITATION_STATUS_AUTH_EXPIRED,
  authPending: MANAGER_INVITATION_STATUS_AUTH_PENDING,
};

export enum SIZE {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const enum VIEW_OPTIONS {
  list = 'list',
  grid = 'grid',
  stacked = 'stacked',
  card = 'card',
}

export enum VIEW_OPTIONS_TRACK {
  list = 'list',
  grid = 'tile',
}

export const enum VIEWS_OPTIONS_MAPPED {
  list = 'list',
  stack = 'stack',
  card = 'card',
}
