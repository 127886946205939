import { useInterval } from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import authApi from '~/authApi';
import clientApi from '~/clientApi';
import { areEqualMajorVersions } from '~/utils';

import { Error503 } from '../Error503';
import {
  MAINTENANCE_MODE_EVENT,
  POLL_INTERVAL,
  UNAVAILABLE_RESOURCE_CODE,
} from './MaintenanceModeInterceptor.constants';
import {
  Status,
  StatusAPIResponseDTO,
} from './MaintenanceModeInterceptor.model';

export interface IMaintenanceModeInterceptorProps {
  children?: ReactNode;
}

export const MaintenanceModeInterceptor: React.FC<
  IMaintenanceModeInterceptorProps
> = ({ children }) => {
  const [isUnderMaintenanceMode, setMaintenanceMode] = useState(false);

  const checkStatus = async () => {
    const { status, releaseId } =
      (await clientApi.status.index()) as StatusAPIResponseDTO;
    const gitTag = process.env.NEXT_PUBLIC_GIT_TAG as string;

    if (status === Status.ok && areEqualMajorVersions(gitTag, releaseId)) {
      setMaintenanceMode(false);

      window.location.reload();
    }
  };

  const handleMaintenanceMode = (
    eventData: CustomEvent<{ isUnderMaintenance: boolean }>,
  ) => {
    if (eventData.detail.isUnderMaintenance) {
      setMaintenanceMode(true);
    } else {
      // Check if error is displayed to avoid multiple renders
      if (isUnderMaintenanceMode) {
        setMaintenanceMode(false);

        window.location.reload();
      }
    }
  };

  const error503Interceptor = (error: any) => {
    const is503 = error?.response?.status === UNAVAILABLE_RESOURCE_CODE;

    // Check not under maintenance to avoid multiple renders
    if (is503 && !isUnderMaintenanceMode) {
      return setMaintenanceMode(true);
    }

    return Promise.reject(error);
  };

  useEffect(() => {
    window.addEventListener(MAINTENANCE_MODE_EVENT, handleMaintenanceMode);

    clientApi._apiInstance.interceptors.response.use(
      undefined,
      error503Interceptor,
    );

    authApi._apiInstance.interceptors.response.use(
      undefined,
      error503Interceptor,
    );

    return () => {
      window.removeEventListener(MAINTENANCE_MODE_EVENT, handleMaintenanceMode);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(checkStatus, isUnderMaintenanceMode ? POLL_INTERVAL : null);

  return <>{isUnderMaintenanceMode ? <Error503 /> : children} </>;
};
