import { ReactNode, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Error500 from '~/components/Error500';
import { captureException } from '~/sentry';

export interface IGlobalErrorBoundary {
  children?: ReactNode;
}

/**
 * This library has stopped receiving support, it's not really compatible with react18.
 * NOTE: children is not included in FC types anymore.
 *  */

const GlobalErrorBoundary: React.FC<IGlobalErrorBoundary> = ({ children }) => {
  const [eventId, setEventId] = useState(null);

  const handleError = (error: Error, info: { componentStack: string }) => {
    const newEventId = captureException(error, { errorInfo: info });
    setEventId(newEventId);
  };

  return (
    // @ts-ignore
    <ErrorBoundary
      fallbackRender={() => <Error500 errorEventId={eventId} />}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
