import ErrorPage from '../ErrorPage';

export const Error503 = () => {
  return (
    <ErrorPage>
      <ErrorPage.MainContent>
        <ErrorPage.Heading>
          We're currently down for maintenance. We'll be back shortly.
        </ErrorPage.Heading>
      </ErrorPage.MainContent>
      <ErrorPage.Image
        accentColor="#FFDDE1"
        srcSet="/images/404_img.jpg, /images/404_imgx2.jpg 2x, /images/404_imgx3.jpg 3x"
      />
    </ErrorPage>
  );
};
