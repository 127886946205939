import { extendTheme, keyframes, theme } from '@chakra-ui/react';
import { themeOverride } from 'ui-components';
import { hexToRgbaString } from '~/utils';

import { Layout, Logo, Menu, Popover, Tabs, checkboxTheme } from './components';
import { globalStyles } from './global';

const loadingAnimation = () => keyframes`
  from {
    border-color: #E4E8EC;
    background-color: #E4E8EC;
  }
  to {
    border-color: #D8D8D8;
    background-color: #D8D8D8;
  }
`;

const overrides = {
  styles: { global: globalStyles },
  fonts: {
    body: "'Founders Grotesk', sans-serif",
    heading: "'Founders Grotesk', sans-serif",
  },
  shadows: {
    ...theme.shadows,
    box: '2px 2px 20px rgba(171, 177, 183, 0.2)',
    custom: '0 2px 4px 0 rgba(0,0,0,0.5)',
    floating: '0 0 10px 0 rgba(0,0,0,0.2)',
    generic: '0px 0px 16px rgba(0, 0, 0, 0.08);', // Unify with menu once KRI-2338 Is merged
    floatingLight: `2px 2px 20px ${hexToRgbaString('#abb1b7', 0.2)}`,
    invitationCard: `3px 3px 10px ${hexToRgbaString('#546ca9', 0.08)}`,
    menu: `0px 0px 16px rgba(0, 0, 0, 0.08);`,
    outline: `0 0 0 1.5px ${hexToRgbaString('#8b94a2', 1)}`,
    subtle: '0 0 10px 0 rgba(0,0,0,0.1)',
  },
  // font sizes are shifted one position from the default. adding a new 11px as xs and removing 64px
  // base font size is therefore 14px
  fontSizes: {
    '2xs': '10px',
    xs: '11px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '28px',
    '5xl': '32px',
    '6xl': '48px',
  },
  letterSpacings: {
    link: '0.004em',
  },
  radii: {
    none: '0',
    '5xl': '2.5rem',
    full: '9999px',
  },
  zIndices: {
    ...theme.zIndices,
    creatorNavigationMenu: theme.zIndices.dropdown + 1,
    dropdown: theme.zIndices.modal + 1,
    foamLoader: 9999,
    managerNavigationMenu: theme.zIndices.dropdown + 2,
  },
  colors: {
    ...theme.colors,
    white: '#ffffff',
    neutral: {
      50: '#fcfcfd',
      100: '#f6f8fa', // background
      200: '#e6e8eb',
      300: '#b3b8bc', // light text
      400: '#828b93',
      500: '#555c62',
      600: '#42484c', // text
      700: '#393e42',
      800: '#383838', // title
      900: '#000000',
    },
    primary: themeOverride.colors.primary,
    secondary: themeOverride.colors.secondary,
    tertiary: {
      50: '#f6f7fe',
      100: '#d9d9f7', // disabled
      200: '#cdccf5', // disabled
      300: '#b4b2f0',
      400: '#a2a0ee',
      500: '#918eeb', // main
      600: '#765eed', // hover
      700: '#644ae8',
      800: '#3b3687',
      900: '#32275d',
    },
    beige: {
      50: '#fafafa',
      100: '#f9f7f6',
      200: '#f1ece9', // main
      300: '#e9e1dd',
      400: '#e0d4cc',
      500: '#cbbcb3',
      600: '#beaca2',
      700: '#9c8b81',
      800: '#635a54',
      900: '#413c3a',
    },
    lightBlue: {
      50: '#f6fbfe',
      100: '#ecf6fd',
      200: '#ddebf9', // main
      300: '#c6def4',
      400: '#a1ccf3',
      500: '#7fafdc',
      550: '#6987C9',
      600: '#5599d8',
      700: '#3278b8',
      800: '#19578f',
      900: '#123c64',
    },
    softPink: {
      50: '#fdf7f7',
      100: '#fbefee',
      200: '#fce5e3', // main
      300: '#ffd0cc',
      400: '#ffbdb8',
      500: '#ffaaa3',
      600: '#ff938a',
      700: '#f7786e',
      800: '#ec5d51',
      900: '#b8392e',
    },
    success: {
      50: '#effbf8',
      100: '#d6f5ee',
      200: '#adebdd',
      300: '#7cdec9',
      400: '#50d3b7',
      500: '#34c0a2', // main
      600: '#2ea98e', // hover
      700: '#258873',
      800: '#1c6858',
      900: '#154c40',
    },
    error: {
      50: '#fcf0ed',
      100: '#f9ddd7',
      200: '#f2b3a6',
      300: '#f59380',
      400: '#ea7962',
      500: '#e35c31', // main
      600: '#d84e22', // hover
      700: '#b9421d',
      800: '#963617',
      900: '#652410',
    },
    purple: '#725aed',
    lightBlack: '#132248',
    additionalColors: {
      // added for verticals
      darkPurple: '#932f6d',
      wineRed: '#420039',
      salmonRed: '#DD5643',
      royalBlue: '#3D3B8E',
      goldenBrown: '#9C6615',
      lightBlueSpecial: '#6987C9',
      darkLavender: '#805D93',
      oldGold: '#B4A222',
      fuchsia: '#CD43B7',
    },

    platforms: {
      youtube: '#d7262b',
    },
  },
  lazyLoad: {
    Loading: {
      animation: `1s ease-in infinite alternate ${loadingAnimation()}`,
      backgroundColor: 'gray.300',
    },
    Error: {
      backgroundColor: 'gray.300',
    },
  },
  components: {
    Layout,
    Divider: {
      baseStyle: {
        opacity: 1,
        borderColor: 'primary.100',
      },
    },
    Button: {
      variants: {
        // The font color is different from stroke
        'no-background': {
          backgroundColor: 'transparent',
          borderColor: 'primary.600',
          borderWidth: 1,
          color: 'primary.500',
          _hover: {
            color: 'primary.600',
          },
        },
      },
    },
    Avatar: {
      sizes: {
        '2xs': {
          container: {
            width: '6',
            height: '6',
            fontSize: 'calc(2rem / 2)',
          },
          excessLabel: { width: '6', height: '6' },
          label: { fontSize: 'calc(2rem / 2)', lineHeight: '2rem' },
        },
        xs: {
          container: {
            width: '8',
            height: '8',
            fontSize: 'calc(2rem / 2)',
          },
          excessLabel: { width: '8', height: '8' },
          label: { fontSize: 'calc(2rem / 2)', lineHeight: '2rem' },
        },
        sm: {
          container: {
            width: '10',
            height: '10',
            fontSize: 'calc(2.5rem / 2.5)',
          },
          excessLabel: { width: '10', height: '10' },
          label: { fontSize: 'calc(2.5rem / 2.5)', lineHeight: '2.5rem' },
        },
      },
    },
    Switch: {
      sizes: {
        md: {
          track: {
            w: '32px',
            h: '10px',
            bg: 'primary.0',
            borderWidth: 1,
            borderColor: 'primary.700',
            alignItems: 'center',
            _checked: {
              bg: 'secondary.500',
              borderWidth: 1,
              borderColor: 'secondary.500',
            },
          },
          thumb: {
            w: '12px',
            h: '12px',
            bg: 'primary.700',
            _checked: {
              bg: 'white',
              transform: 'translateX(1.25em)',
            },
          },
        },
      },
    },
    FormError: {
      baseStyle: {
        text: {
          color: 'error.500',
        },
      },
    },
    Logo,
    Menu,
    Popover,
    Tabs,
    Checkbox: checkboxTheme,
  },
};

const customTheme: Record<string, any> = extendTheme(overrides);

export default customTheme;
