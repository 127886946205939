import {
  DehydratedState,
  Hydrate,
  QueryClientProvider,
} from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import * as React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider, themeOverride } from 'ui-components';
import { HeapInitializer } from '~/components/HeapInitializer';
import { queryClient } from '~/config/reactQueryConfig';
import { IntercomProvider } from '~/contexts/Intercom/IntercomContext';
import { MetricsProvider } from '~/contexts/Metrics';
import { AuthProvider } from '~/contexts/auth';
import { useSentrySetUser } from '~/hooks/useSentrySetUser';
import store from '~/store';
import customTheme from '~/styles/theme';

import GlobalErrorBoundary from '../GlobalErrorBoundary';
import { MaintenanceModeInterceptor } from '../MaintenanceModeInterceptor';

const DevTools = dynamic(() =>
  import('@tanstack/react-query-devtools').then(
    ({ ReactQueryDevtools }) => ReactQueryDevtools,
  ),
);

function ReduxInnerProviders({
  children,
  isSSR,
  dehydratedState,
}: {
  children: React.ReactNode;
  isSSR: boolean;
  dehydratedState: DehydratedState;
}) {
  // Initialize user info in Sentry
  useSentrySetUser();

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        {process.env.NODE_ENV !== 'production' && (
          <DevTools
            initialIsOpen={false}
            toggleButtonProps={{ style: { right: 'unset' } }}
          />
        )}

        <ThemeProvider
          fonts={['400', '500']}
          theme={{
            ...customTheme,
            components: {
              ...customTheme.components,
              DatePicker: {
                baseStyle: {
                  calendar: {
                    zIndex: customTheme.zIndices.modal + 1,
                  },
                },
              },
              Select: {
                ...themeOverride.components.Select,
              },
            },
          }}
        >
          <MetricsProvider>
            <IntercomProvider>
              <GlobalErrorBoundary>
                <MaintenanceModeInterceptor>
                  <AuthProvider skipLoading={isSSR}>{children}</AuthProvider>
                </MaintenanceModeInterceptor>
              </GlobalErrorBoundary>
            </IntercomProvider>
          </MetricsProvider>
          <HeapInitializer />
        </ThemeProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export function AppProviders({
  children,
  isSSR,
  dehydratedState,
}: {
  children: React.ReactNode;
  isSSR: boolean;
  dehydratedState: DehydratedState;
}) {
  return (
    <ReduxProvider store={store}>
      <ReduxInnerProviders isSSR={isSSR} dehydratedState={dehydratedState}>
        {children}
      </ReduxInnerProviders>
    </ReduxProvider>
  );
}
