import { ApiModule } from '~/clientApi/ApiModule';

const MODULE = 'yt-contents';

const extraEndpoints = {
  share: `POST ${MODULE}/:id/share`,
  removePassword: `DELETE ${MODULE}/:id/share/password`,
};

const ytContent = (apiInstance) =>
  new ApiModule(apiInstance, MODULE, extraEndpoints);

export default ytContent;
