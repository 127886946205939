import authApi from './authApi';
import { EXTENSION_ACTIONS } from './constants';
import { IExtensionMessage } from './types/extension';

export const chromeApi = typeof chrome !== 'undefined' ? chrome : undefined;

export const sendTokenToChromeExtension = () => {
  chromeApi?.runtime?.sendMessage(
    process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID ?? '',
    {
      action: EXTENSION_ACTIONS.login,
      jwt: authApi.jwtAccessToken,
    },
  );
};

export const sendLogoutToChromeExtension = () => {
  return chromeApi?.runtime?.sendMessage(
    process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID ?? '',
    {
      action: EXTENSION_ACTIONS.logout,
    },
  );
};

export const sendRedirectRequestToChromeExtension =
  async (): Promise<IExtensionMessage | void> => {
    return new Promise(
      (resolve) =>
        chromeApi?.runtime?.sendMessage(
          process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID ?? '',
          {
            action: EXTENSION_ACTIONS.redirectReady,
          },
          resolve,
        ),
    );
  };

export const sendToggleIframeToChromeExtension = () => {
  return chromeApi?.runtime?.sendMessage(
    process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID ?? '',
    {
      action: EXTENSION_ACTIONS.toggleIframe,
    },
  );
};
