import Axios from 'axios';
import { ApiModule } from '~/clientApi/ApiModule';

const MODULE = 'multi-frames';

const multiframes = (apiInstance) => {
  const extraEndpoints = {
    downloadAssets: `POST ${MODULE}/:id/download`,
    share: `POST ${MODULE}/:id/share`,
    contents: `GET ${MODULE}/:id/contents`,
    removePassword: `DELETE ${MODULE}/:id/share/password`,
    getVisibilities: `GET ${MODULE}/:id/visibility`,
    updateVisibilities: {
      method: 'PATCH',
      transformRequest: [
        function ({ id: _id, ...data }) {
          return data;
        },
        ...Axios.defaults.transformRequest,
      ],
      url: `${MODULE}/:id/visibility`,
    },
  };

  return new ApiModule(apiInstance, MODULE, extraEndpoints);
};

export default multiframes;
