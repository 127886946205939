import { SOCIAL_PLATFORMS } from 'ui-components';

export enum SIGNUP_ROUTES {
  signUpConnectionSuccess = '/signup/connection-success',
  signUpConnectSocials = '/signup/connect-socials',
}

export interface SSORoutes {
  loginSSO: string;
  logoutSSO: string;
}

export interface SignUpRoutes {
  signUpConnectSocials: string;
  signUpConnectionSuccess: string;
}

export interface EchoRoutes {
  echoMessages: string;
  echoPayments: string;
  echoEditProfile: string;
}

export interface SettingsRoutes {
  settingsNotificationsPreferences: string;
  settingsProfile: string;
  settingsAgency: string;
}

export interface AgencyRoutes {
  agencySettings: string;
  agencyTalent: string;
}

export interface DemoRoutes {
  contentSearchAutocomplete: string;
}

export interface SwitchRoutes
  extends EchoRoutes,
    SettingsRoutes,
    SignUpRoutes,
    SSORoutes,
    AgencyRoutes,
    DemoRoutes {
  agency: string;
  connectionDashboard: string;
  connectionDashboardEntry: string;
  connectionDashboardLogin: string;
  connectionDashboardSignup: string;
  contentSearch: string;
  creatorBrandDeals: string;
  creatorCampaignReportList: string;
  creatorLiveContent: string;
  creatorLiveContentId: string;
  creatorliveContentIdTikTok: string;
  creatorliveContentIdYouTube: string;
  creatorMediaPackList: string;
  creatorMultiframeStories: string;
  echoWelcomeScreen: string;
  emailAssistant: string;
  emailAssistantResult: string;
  extensionAuthCallback: string;
  extensionAuthCallbackToTutorial: string;
  foamAi: string;
  logout: string;
  managerDashboard: string;
  managerRoster: string;
  mediaPackChooseTemplate: string;
  mediaPackDetail: string;
  mediaPacks: string;
  mediaPackSharedView: string;
  myAgency: string;
  myRoster: string;
  notFound: string;
  unauthorized: string;
  platformConnections: string;
  privacyPolicy: string;
  profileOverview: string;
  reconnectPlatforms: string;
  root: string;
  savedLists: string;
  savedListDetail: string;
  sharedMediaPack: string;
  sharedMediaPackLiveContent: string;
  sharedProfileOverview: string;
  slackAuthCallback: string;
  slackCallback: string;
  termsAndConditions: string;
}

export type SwitchParams = {
  [name: string]: string | string[];
};

export type SwitchOptionalParams = {
  [name: string]: boolean | string | string[] | undefined;
};
export interface Routes
  extends Omit<
    SwitchRoutes,
    | 'connectionDashboardEntry'
    | 'connectionDashboardLogin'
    | 'connectionDashboardSignup'
    | 'mediaPackDetail'
    | 'creatorLiveContentId'
    | 'creatorliveContentIdTikTok'
    | 'creatorliveContentIdYouTube'
    | 'sharedMediaPack'
    | 'sharedMediaPackLiveContent'
    | 'profileOverview'
    | 'sharedProfileOverview'
    | 'slackCallback'
    | 'slackAuthCallback'
    | 'savedListDetail'
  > {
  connectionDashboardEntry: (token: string) => string;
  connectionDashboardLogin: (token: string) => string;
  connectionDashboardSignup: (token: string) => string;
  mediaPackDetail: (id: string, firstTime?: boolean) => string;
  mediaPackShareView: (id: string) => string;
  creatorLiveContentId: (contentId: string) => string;
  creatorliveContentIdTikTok: (
    contentId: string,
    platform: SOCIAL_PLATFORMS,
  ) => string;
  creatorliveContentIdYouTube: (
    contentId: string,
    platform: SOCIAL_PLATFORMS,
  ) => string;
  profileOverview: (slug: string) => string;
  sharedProfileOverview: (slug: string) => string;
  sharedMediaPack: (sharedToken: string) => string;
  sharedMediaPackLiveContent: (sharedToken: string, sid: string) => string;
  slackCallback: (
    accessToken: string,
    refreshToken: string,
    userId: string,
    state: string,
  ) => string;
  slackAuthCallback: (userId: string, state: string) => string;
  savedListDetail: (id: string) => string;
  contentSearchDetailModal: (id: string) => string;
}
