import { IAuthContextParams } from './auth.vm';

/**
 * Maps a URLSearchParams object into a key-value object.
 *
 * **NOTE**: it gets rid of all non key-value data in the query, as example, **canGoBack**.
 * @param {URLSearchParams} params - URLSearchParams.
 */
export const mapURLSearchParamsToParams = (params: URLSearchParams) =>
  Array.from(params.keys()).reduce((acc: IAuthContextParams, key) => {
    const value = params.get(key);
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});
