export enum Status {
  ok = 'ok',
  maintenance = 'maintenance',
}

export interface StatusAPIResponseDTO {
  version: string;
  status: string;
  releaseId: string;
}
