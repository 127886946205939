import { FEATURE_FLAGS } from '~/constants';

import { FlagStore } from './store';

const allFlags = Object.values(FEATURE_FLAGS);

export const getFlagValues = (enabledFeatures: FEATURE_FLAGS[]) => {
  return allFlags.reduce((acc, flagName) => {
    return {
      ...acc,
      [flagName]: {
        value: enabledFeatures.includes(flagName),
      },
    };
  }, {}) as FlagStore['flags'];
};
