import { CONTENT_TYPES } from './platforms';

export const DEFAULT_WIDTH = 1080;
export const DEFAULT_HEIGHT = 1080;

export const ASPECT_RATIO = {
  [CONTENT_TYPES.post]: {
    width: 1080,
    height: 1080,
    ratio: '1 / 1',
  },
  [CONTENT_TYPES.story]: {
    width: 1080,
    height: 1920,
    ratio: '9 / 16',
  },
  [CONTENT_TYPES.reels]: {
    width: 1080,
    height: 1920,
    ratio: '9 / 16',
  },
  [CONTENT_TYPES.tiktok]: {
    width: 1080,
    height: 1920,
    ratio: '9 / 16',
  },
  [CONTENT_TYPES.youtube]: {
    width: 1920,
    height: 1080,
    ratio: '16 / 9',
  },
} as const;
