import { As, Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import * as React from 'react';

const LinkButton = forwardRef<ButtonProps, As>((props, ref) => (
  <Button
    ref={ref}
    textTransform="initial"
    letterSpacing="normal"
    fontSize="md"
    fontWeight="medium"
    {...props}
    variant="link"
  />
));

LinkButton.propTypes = Button.propTypes;
LinkButton.defaultProps = Button.defaultProps;

export default LinkButton;
