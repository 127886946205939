export enum METRICS {
  metric1 = 'metric1',
  metric2 = 'metric2',
  metric3 = 'metric3',
}
export enum METRICS_NAME {
  metric1 = 'Metric 1',
  metric2 = 'Metric 2',
  metric3 = 'Metric 3',
}
