import { ApiModule } from '~/clientApi/ApiModule';

const MODULE = 'yt-channels';

const extraEndpoints = {
  insights: `GET ${MODULE}/:id/insights`,
};

const ytChannel = (apiInstance) => {
  return new ApiModule(apiInstance, MODULE, extraEndpoints);
};

export default ytChannel;
