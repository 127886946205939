import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import cookie from 'js-cookie';
import authApi from '~/authApi';
import { MAINTENANCE_MODE_EVENT } from '~/components/MaintenanceModeInterceptor/MaintenanceModeInterceptor.constants';
import {
  HEADER_NAME_API_VERSION,
  HEADER_NAME_ORIGINAL_USERID,
  LOCALSTORAGE_KEY_IMPERSONATOR_ID,
} from '~/constants';
import { areEqualMajorVersions, getAndCleanImpersonatedParams } from '~/utils';

import agencies from './agencies';
import agenciesManagement from './agenciesManagement';
import assets from './assets';
import benchmarks from './benchmarks';
import content from './content';
import creatorBrands from './creator-brands';
import creatorInsights from './creator-insights';
import creators from './creators';
import { transformHydraResources } from './hydra-utils';
import insights from './insights';
import invitations from './invitations';
import locations from './locations';
import managers from './managers';
import { mediaPacks } from './mediaPacks';
import multiframes from './multiframes';
import notifications from './notifications';
import offPlatform from './offPlatform';
import savedLists from './savedLists';
import settings from './settings';
import shared from './shared';
import status from './status';
import ttContents from './ttContents';
import ttInsights from './ttInsights';
import ytChannel from './ytChannels';
import ytContent from './ytContent';

export const BASE_URL_SUFFIX = 'api';
export const JSON_LD_HEADER_ACCEPT = 'application/ld+json';

/**
 * @deprecated ClientApi should no longer be used to add new api/endpoints.
 * Use apiClientV2 instead from `src/api/client.ts`
 */
export class ClientApi {
  constructor({ createInterceptors } = { createInterceptors: true }) {
    this._apiInstance = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_API_RESOURCE}/${BASE_URL_SUFFIX}`,
      headers: {
        Accept: JSON_LD_HEADER_ACCEPT,
      },
    });

    this._sharedToken = null;
    this._sharedTokenPassword = null;
    this.content = content(this._apiInstance);
    this.creators = creators(this._apiInstance);
    this.multiframes = multiframes(this._apiInstance);
    this.insights = insights(this._apiInstance);
    this.creatorBrands = creatorBrands(this._apiInstance);
    this.assets = assets(this._apiInstance);
    this.mediaPacks = mediaPacks(this._apiInstance);
    this.benchmarks = benchmarks(this._apiInstance);
    this.creatorInsights = creatorInsights(this._apiInstance);
    this.locations = locations(this._apiInstance);
    this.invitations = invitations(this._apiInstance);
    this.managers = managers(this._apiInstance);
    this.shared = shared(this._apiInstance);
    this.notifications = notifications(this._apiInstance);
    this.ttInsights = ttInsights(this._apiInstance);
    this.ttContents = ttContents(this._apiInstance);
    this.settings = settings(this._apiInstance);
    this.ytContent = ytContent(this._apiInstance);
    this.ytChannel = ytChannel(this._apiInstance);
    this.status = status(this._apiInstance);
    this.savedLists = savedLists(this._apiInstance);
    this.agencies = agencies(this._apiInstance);
    this.agenciesManagement = agenciesManagement(this._apiInstance);
    this.offPlatform = offPlatform(this._apiInstance);

    if (createInterceptors) {
      this._apiInstance.interceptors.response.use(({ data, headers = {} }) => {
        // There is an issue (https://github.com/cypress-io/cypress/issues/2879)
        // where Cypress converts the headers to lowercase,
        // and we receive in uppercase from Backend API,
        // so we need to check for the headers key in uppercase
        // (in case we are just running the app) or in lowercase
        // (in case we are running the app through Cypress).
        const apiMajorVersion =
          headers[HEADER_NAME_API_VERSION] ||
          headers[HEADER_NAME_API_VERSION.toLowerCase()];

        if (apiMajorVersion) {
          const gitTag = process.env.NEXT_PUBLIC_GIT_TAG;

          // Because this interceptor means that the other interceptors
          // in the whole application
          // are only receiving the data,
          // and not all the Axios configuration,
          // and we need this axios config to access headers
          window.dispatchEvent(
            new CustomEvent(MAINTENANCE_MODE_EVENT, {
              detail: {
                isUnderMaintenance: !areEqualMajorVersions(
                  gitTag,
                  apiMajorVersion,
                ),
              },
            }),
          );
        }

        return transformHydraResources(data);
      });

      this._apiInstance.interceptors.request.use((req) => {
        // get shared token from params (get), data (post) or {}
        // so it doesn't throw if no params or data
        const { _sharedToken } = req?.params || req?.data || {};
        // get access token from authApi
        const accessToken = authApi.accessToken;

        if (_sharedToken) {
          // if it's a request with shared token set that as auth header...
          req.headers['Authorization'] = `Bearer ${_sharedToken}`;
          this._sharedToken = _sharedToken;
          delete req?.data?._sharedToken;
          delete req?.params?._sharedToken;
        } else if (accessToken) {
          // ...if it's a request with normal auth, set jwt token as auth header
          req.headers['Authorization'] = `Bearer ${accessToken}`;
          this._sharedToken = null;
        } else {
          // else delete header
          this._sharedToken = null;
          delete req.headers['Authorization'];
        }

        this.cleanIdParam(req);

        const { _isImpersonatingDisabled, _impersonateOnce } =
          getAndCleanImpersonatedParams(req);

        if (
          !_isImpersonatingDisabled &&
          (this.impersonatingId || _impersonateOnce)
        ) {
          req.headers['X-AUTH-IMPERSONATE'] = _impersonateOnce;
        }

        if (this._sharedTokenPassword) {
          req.headers['X-AUTH-ACCESSCODE'] = this._sharedTokenPassword;
        }

        const impersonatorId = window.localStorage.getItem(
          LOCALSTORAGE_KEY_IMPERSONATOR_ID,
        );
        if (impersonatorId && !req.headers[HEADER_NAME_ORIGINAL_USERID]) {
          req.headers[HEADER_NAME_ORIGINAL_USERID] = impersonatorId;
        }

        return req;
      });
    }

    // this will be run whenever there is a 401
    createAuthRefreshInterceptor(this._apiInstance, (failedRequest) => {
      // only make the request if it's not coming from a shared token page
      if (!this._sharedToken) {
        return authApi
          .refreshAccessToken()
          .then((newToken) => {
            failedRequest.response.config.headers['Authorization'] =
              `Bearer ${newToken?.accessToken}`;
            if (newToken) {
              return Promise.resolve();
            } else {
              return Promise.reject();
            }
          })
          .catch(() => {
            return Promise.reject();
          });
      }
      // The function must always return a promise https://github.com/Flyrell/axios-auth-refresh#parameters
      return Promise.resolve();
    });
  }

  cleanIdParam(req) {
    delete req?.params?.id;
    delete req?.data?.['@id'];
  }

  /**
   * impersonatingId getter.
   * @returns {?string}
   */
  get impersonatingId() {
    return cookie.get('impersonatingId');
  }

  /**
   * impersonatingId setter.
   *
   * @param {?string} newImpersonatingId
   */
  set impersonatingId(newImpersonatingId) {
    if (newImpersonatingId) {
      cookie.set('impersonatingId', newImpersonatingId, { expires: 1 });
    } else {
      return cookie.remove('impersonatingId');
    }
  }

  /**
   * sharedTokenPassword getter.
   * @returns {?string}
   */
  get sharedTokenPassword() {
    return this._sharedTokenPassword;
  }

  /**
   * sharedTokenPassword setter.
   *
   * @param {?string} newSharedTokenPassword
   */
  set sharedTokenPassword(newSharedTokenPassword) {
    this._sharedTokenPassword = newSharedTokenPassword;
  }

  /**
   * getUserMetrics getter
   * @returns {Promise<import('./clientApi.api-model').UserMetricsResponse>}
   */
  getUserMetrics() {
    return this._apiInstance.get('/user/metrics', {
      params: { _isImpersonatingDisabled: true },
    });
  }

  /**
   * getSharedManagers getter
   * @param {string} creatorId - id of creator
   * @returns {Promise<import('./sharedManagers.model').SharedManagers>}
   */
  getSharedManagers(creatorId) {
    return this._apiInstance.get('/co-managers', {
      params: {
        creator: creatorId,
        // active param is used to fetch managers that are active. To get current shared managers this param should be true
        active: 1,
      },
    });
  }

  /**
   * getInvitationStatus getter
   * @param {string} token
   * @returns {Promise<import('./clientApi.api-model').UserInvitationStatusResponse>}
   */
  getInvitationStatus(token = undefined) {
    const headers = {};

    if (token) {
      headers['X-AUTH-TOKEN'] = token;
    }

    return this._apiInstance.get('/invitations/status', {
      headers,
      params: { _isImpersonatingDisabled: true },
    });
  }

  /**
   *
   * @returns {Promise<import('./clientApi.api-model').IUserSettings>}
   */
  getUserSettings() {
    return this._apiInstance.get('/user/settings', {
      params: { _isImpersonatingDisabled: true },
    });
  }

  /**
   *
   * @param queryParams {import('./search.model').IApiSearchContentParams}
   * @returns {Promise<import('./search.model').IApiSearchContentResponse>}
   */
  search(queryParams) {
    return this._apiInstance.get('/search', {
      params: queryParams,
    });
  }

  /**
   * getAgencies getter
   * @param {string} slug
   * @returns {Promise<import('./agencies.model.ts').Agency>}
   */
  getAgencies(slug) {
    const headers = {};
    if (!authApi.user?.id || Boolean(slug)) {
      headers['X-AUTH-TOKEN'] = `agency-share-${slug}`;
      headers['authorization'] = undefined;
    }

    return this._apiInstance.get('/agencies', {
      headers,
      params: { _isImpersonatingDisabled: true },
    });
  }

  /**
   * Update enabledControls.
   * @param {Array.<string>} enabledControls
   * @param {string} agencyId
   * @returns {Promise}
   */
  updateAgencyControls(enabledControls, agencyId) {
    return this._apiInstance.patch(
      `/agencies/${agencyId}`,
      {
        enabledControls,
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
      },
    );
  }

  // TODO: This method is deprecated. Please, use clientApi.agencies.creators
  /**
   * getAgencyCreators - lists the creators in an agency
   * @param {Object} agency - The employees who are responsible for the project.
   * @param {string} agency[].agencyId - The name of an employee.
   * @param {string=} agency[].slug
   * @param {number=} agency[].page
   * @param {number} agency[].pageSize
   * @param {string[]=} agency[].filters
   * @param {string=} agency[].sort
   * @returns {Promise<import('./clientApi.types').IClientApiResponse<import('./creator.model').Creator>>}
   */
  getAgencyCreators({
    agencyId,
    slug,
    filters,
    sort,
    page = 1,
    pageSize = 50,
  }) {
    let headers;
    if (!authApi.user?.id || Boolean(slug)) {
      headers = {
        'X-AUTH-TOKEN': `agency-share-${slug}`,
        authorization: '',
      };
    }

    return this._apiInstance.get(`/agencies/${agencyId}/creators`, {
      headers,
      params: {
        'filter[]': filters,
        'sort[]': sort,
        page,
        pageSize,
        _isImpersonatingDisabled: true,
      },
    });
  }

  /**
   * getTrCreators - lists the creators of a talent rep
   * @param {Object} manager - The employees who are responsible for the project.
   * @param {string} manager[].managerId
   * @param {number} manager[].page
   * @param {number} manager[].pageSize
   * @param {string[]} manager[].filters
   * @param {string} manager[].sort
   * @returns {Promise<import('./clientApi.types').IClientApiResponse<import('./creator.model').Creator>>}
   */
  getTrCreators({ managerId, filters, sort, page = 1, pageSize = 50 }) {
    let headers;
    if (managerId.startsWith('manager-share')) {
      headers = {
        'X-AUTH-TOKEN': managerId,
      };
    }

    return this._apiInstance.get(`/managers/${managerId}/creators`, {
      headers,
      params: {
        'filter[]': filters,
        'sort[]': sort,
        page,
        pageSize,
        _isImpersonatingDisabled: true,
      },
    });
  }

  // This function is duplicated in clientApi.managers.index()
  // Unfortunately we need this code block to make requests via share token
  /**
   * getManagerDetailsFromShareToken - get Managers details from share token
   * @param {Object} manager
   * @param {import('~/hooks/useGetAuthToken').AuthToken} manager[].shareToken
   * @returns {Promise<import('./clientApi.types').IClientApiResponse<import('./managers.model').Manager>>}
   */
  getManagerDetailsFromShareToken({ shareToken }) {
    let headers;
    if (Boolean(shareToken)) {
      headers = {
        'X-AUTH-TOKEN': shareToken,
        authorization: '',
      };
    }

    return this._apiInstance.get(`/managers`, {
      headers,
    });
  }

  /**
   * getCreatorProfile - lists the creators in an agency
   * @param {Object} slug - The Creator slug.
   * @returns {Promise<import('./rceator.model').Creator>}
   */
  getCreatorProfile({ slug }) {
    let headers = {};
    if (!authApi.user?.id) {
      headers = { authorization: `Bearer creator-share-${slug}` };
    }
    return this._apiInstance.get(`/creators/profile/${slug}`, {
      headers,
    });
  }

  getCreatorContent({
    creatorId,
    platform,
    authToken,
    page = 1,
    pageSize = 10,
  }) {
    let headers = {};
    if (authToken) {
      headers['X-AUTH-TOKEN'] = authToken;
    }

    return this._apiInstance.get(`/search`, {
      headers,
      params: {
        creatorId,
        platform,
        page,
        pageSize,
      },
    });
  }
}

/**
 * @deprecated ClientApi should no longer be used to add new api/endpoints.
 * Use apiClientV2 instead from `src/api/client.ts`
 */
const clientApi = new ClientApi();

/**
 * @deprecated ClientApi should no longer be used to add new api/endpoints.
 * Use apiClientV2 instead from `src/api/client.ts`
 */
export default clientApi;

export * from './clientApi.mapper';
export * from './clientApi.types';
