import { captureException } from '~/sentry';

const REQUIRED_PERMISSIONS = [
  'public_profile',
  'pages_show_list',
  'pages_read_engagement',
  'pages_manage_metadata',
  'pages_read_user_content',
  'instagram_basic',
  'instagram_manage_insights',
  'business_management',
];

const REQUIRED_FIELDS = [
  'instagram_business_account{id,name,username,profile_picture_url}',
  'name',
  'category',
  'id',
];

const mapAccount = (account) => ({
  accountAccessToken: account?.accessToken,
  instagramBusinessAccountId: account?.instagram_business_account?.id,
  accountId: account.id,
  category: account.category,
  name: account.name,
});

function preCheck(FBobj) {
  if (!FBobj) {
    throw Error('Facebook SDK not loaded');
  }
}

const facebook = () => {
  let FBApi = null;

  const setFBApi = (FBobj) => {
    FBApi = FBobj;
  };

  /** Initializes Facebook SDK. Run as early as possible */
  const init = () => {
    if (!FBApi) {
      window.fbAsyncInit = function fbAsyncInit() {
        FB.init({
          appId: process.env.NEXT_PUBLIC_FB_APP_ID,
          cookie: true,
          version: 'v17.0',
        });
        FBApi = FB;
      };

      (function fbInject(d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  };

  /**
   * @param {[string]} args.permissions
   * @returns {Promise}
   */
  const login = (args = {}) => {
    const { permissions = [] } = args;
    return new Promise((resolve, reject) => {
      preCheck(FBApi);
      FBApi.login(
        (response) => {
          if (response.error || !response.authResponse) {
            reject(new Error('unknown'));
          }
          return resolve(response);
        },
        { scope: permissions.join(','), return_scopes: true },
      );
    });
  };

  /**
   * @param {[string]} args.fields
   * @param {string} args.userID
   * @param {string} args.next Url for next page if available
   * @returns {Promise}
   */
  const retrieveAccounts = (args = {}) => {
    const { fields = [], userID, next } = args;
    const url =
      next || `/${userID}/accounts?limit=${50}&fields=${fields.join(',')}`;
    return new Promise((resolve, reject) => {
      preCheck(FBApi);
      FBApi.api(url, (response) => {
        if (response.error) {
          reject(new Error(response.error.message));
        }
        return resolve(response);
      });
    });
  };

  /**
   * Authorises and retrieves the account belonging to the handle provided
   * @param {string} handle
   */
  const retrieveAccountForHandle = async (handle) => {
    const { authResponse } = await login({
      permissions: REQUIRED_PERMISSIONS,
    });
    const { grantedScopes, accessToken } = authResponse;
    const permissions = grantedScopes?.split(',');
    // check permission
    const hasPermissions = REQUIRED_PERMISSIONS.every((requiredPermission) =>
      permissions?.includes(requiredPermission),
    );
    if (!hasPermissions) {
      const err = new Error('no-permissions');
      captureException(err, { errorInfo: authResponse });
      throw err;
    }
    // fetch all accounts
    let next = null;
    let response;
    let accounts = [];
    do {
      response = await retrieveAccounts({
        fields: REQUIRED_FIELDS,
        userID: authResponse.userID,
        next,
      });
      accounts = [...accounts, ...response.data];
      next = response?.paging?.next || null;
    } while (next);

    const businessAccounts = accounts.filter(
      (account) => account.instagram_business_account,
    );

    const account = businessAccounts.find(
      ({ instagram_business_account }) =>
        instagram_business_account.username?.toLowerCase() ===
        handle.toLowerCase(),
    );

    return account && mapAccount({ ...account, accessToken });
  };

  return {
    init,
    login,
    retrieveAccounts,
    setFBApi,
    retrieveAccountForHandle,
  };
};

export default facebook();
