import { noop } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '~/selectors/user';

import { MetricsContext } from '../Metrics';
import { INTERCOM_EVENTS } from './IntercomContext.constants';
import { mapMetricsFromApiToVm } from './IntercomContext.mapper';

interface Context {
  shutdown: () => void;
  sendMessage: (message: string) => void;
}

export const IntercomContext = React.createContext<Context>({
  shutdown: noop,
  sendMessage: noop,
});

export interface IIntercomProviderProps {
  children?: React.ReactNode;
}

export const IntercomProvider: React.FC<IIntercomProviderProps> = ({
  children,
}) => {
  const user = useSelector(selectUser);
  const metrics = useContext(MetricsContext);

  const initializeIntercom = React.useCallback(() => {
    if (window.Intercom) {
      const data = Boolean(user?.id)
        ? mapMetricsFromApiToVm(metrics)
        : undefined;

      window.Intercom(INTERCOM_EVENTS.boot, {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '',
        ...(data ?? {
          Registered: false,
        }),
      });
    }
  }, [metrics, user?.id]);

  useEffect(() => {
    initializeIntercom();
  }, [initializeIntercom]);

  const shutdown = () => {
    if (window.Intercom) {
      window.Intercom(INTERCOM_EVENTS.shutdown);
    }
  };

  const sendMessage = (message: string) => {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message);
    }
  };

  return (
    <IntercomContext.Provider
      value={{
        shutdown,
        sendMessage,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};
