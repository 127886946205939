import { useMemo } from 'react';
import { FEATURE_FLAGS } from '~/constants';

import { useFeature } from './useFeature';

export const enum FEATURE_STATUS {
  disabled = 'disabled',
  enabled = 'enabled',
  error = 'error',
  loading = 'loading',
}

/**
 * @deprecated Use `useFeature()` instead
 */
export const useFeatureFlag = (flag: FEATURE_FLAGS) => {
  const feature = useFeature(flag);

  const flagState = useMemo(() => {
    if (feature.status === 'loading') return 'loading';
    if (feature.status === 'error') return 'error';

    if (feature.isEnabled) {
      return 'enabled';
    }

    return 'disabled';
  }, [feature]);

  return flagState;
};
