import { configureStore } from '@reduxjs/toolkit';

import * as rootReducer from './ducks';

export const makeStore = (options) =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
    ...options,
  });

const store = makeStore();

export default store;
