import axios, { AxiosInstance } from 'axios';
import { ApiModule } from '~/clientApi/ApiModule';

import {
  ManagerApiModule,
  PostSharedCreatorsRequestBody,
  RemoveSharedCreatorsRequestBody,
} from './managers.model';

const MODULE = 'managers';

const defaultTransformRequest = Array.isArray(axios.defaults.transformRequest)
  ? axios.defaults.transformRequest
  : [];
const managers = (apiInstance: AxiosInstance) => {
  const extraEndpoints = {
    invitations: `GET ${MODULE}/:id/invitations`,
    shareCreators: {
      method: 'POST',
      transformRequest: [
        function ({ id: _, ...data }: PostSharedCreatorsRequestBody) {
          return data;
        },
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:id/co-managers`,
    },
    unshareCreators: {
      method: 'PUT',
      transformRequest: [
        function ({ id: _, ...data }: PostSharedCreatorsRequestBody) {
          return data;
        },
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:id/co-managers`,
    },
    removeSharedCreators: {
      method: 'PUT',
      transformRequest: [
        function ({ id: _, ...data }: RemoveSharedCreatorsRequestBody) {
          return data;
        },
        ...defaultTransformRequest,
      ],
      url: `/co-managers/:id`,
    },
    addCoManagers: {
      method: 'POST',
      transformRequest: [
        function ({
          creatorsIds,
          managersEmails,
          message,
        }: {
          creatorsIds: string[];
          managersEmails: string[];
          message?: string;
        }) {
          return {
            creators: creatorsIds,
            managerEmails: managersEmails,
            message,
          };
        },
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:userId/co-managers`,
    },
    exists: `POST ${MODULE}/exists`,
    benchmarks: `POST ${MODULE}/:id/creators/benchmarks`,
    insights: `POST ${MODULE}/:id/creators/insights`,
  };

  return new ApiModule(apiInstance, MODULE, extraEndpoints) as ManagerApiModule;
};

export default managers;
