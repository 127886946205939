import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { transformPxToRem } from 'ui-components';

export const LAYOUT_WIDTH = {
  compact: transformPxToRem(1008),
  restricted: transformPxToRem(1440),
} as const;

export const Layout: ComponentMultiStyleConfig = {
  parts: ['content', 'root', 'main'],
  baseStyle: {
    content: {
      width: '100%',
      marginX: 'auto',
      paddingY: 'spacing-400',
      paddingX: 'spacing-300',
    },
    root: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    main: {
      alignItems: 'stretch',
      backgroundColor: 'primary.0',
      flex: '1 1 0',
      flexDirection: 'column',
      overflow: 'visible',
      position: 'relative',
    },
  },
  variants: {
    compact: {
      content: {
        maxWidth: LAYOUT_WIDTH.compact,
      },
    },
    restricted: {
      content: {
        maxWidth: LAYOUT_WIDTH.restricted,
      },
    },
    fluid: {},
  },
  defaultProps: {
    variant: 'fluid',
  },
};
