export enum EXTENSION_ACTIONS {
  login = 'login',
  logout = 'logout',
  redirect = 'redirect',
  redirectReady = 'redirectReady',
  toggleIframe = 'toggleIframe',
}

export enum EXTENSION_REDIRECT_TYPE {
  rfp = 'rfp',
}
