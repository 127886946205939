import axios, { AxiosInstance } from 'axios';
import { ApiModule, Config } from '~/clientApi/ApiModule';

import { MediaPacksApiModule } from './mediaPacks.model';

const MODULE = 'media-packs';

export const mediaPacks = (apiInstance: AxiosInstance) => {
  const defaultTransformRequest = Array.isArray(axios.defaults.transformRequest)
    ? axios.defaults.transformRequest
    : [];

  const extraEndpoints: Config = {
    share: `POST ${MODULE}/:id/share`,
    duplicate: 'POST /media-packs/:id/duplicate',
    removePassword: `DELETE ${MODULE}/:id/share/password`,
    getVisibilities: `GET ${MODULE}/:id/visibility`,
    updateVisibilities: {
      method: 'PATCH',
      transformRequest: [
        function ({ id: _, ...data }) {
          return data;
        },
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:id/visibility`,
    },

    igInsights: `GET ${MODULE}/:id/ig-insights`,
    ttInsights: `GET ${MODULE}/:id/tt-insights`,
    ytInsights: `GET ${MODULE}/:id/yt-insights`,
  };

  return new ApiModule(
    apiInstance,
    MODULE,
    extraEndpoints,
  ) as MediaPacksApiModule;
};
