import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import clientApi from '~/clientApi';
import { FEATURE_FLAGS } from '~/constants';
import { routes } from '~/core/router';
import { fetchCreator } from '~/ducks/creator';
import { useFeatureFlag } from '~/hooks/useFeatureFlag';
import { CreatorType } from '~/types';

const useImpersonateCreator = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const router = useRouter();
  const isCreatorProfileEnabled =
    useFeatureFlag(FEATURE_FLAGS.foamCreatorProfile) === 'enabled';

  const setImpersonatedCreator = React.useCallback(
    async (creator: Pick<CreatorType, 'id' | 'slug'>, redirectUrl?: string) => {
      queryClient.clear();

      // clientApi.impersonatingId = creator.id;
      window.sessionStorage.clear();

      if (isCreatorProfileEnabled) {
        await router.push(
          redirectUrl ?? routes.profileOverview(creator.slug as string),
        );
      } else {
        await router.push(redirectUrl ?? routes.mediaPacks);
      }

      // Set creator after only after routing so we don't trigger a rerender until the page has loaded
      // @ts-ignore We don't need to pass any more parameters,
      // but TypeScript tells us that this method needs more parameters
      dispatch(fetchCreator.fulfilled(creator));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const clearImpersonatedCreator = React.useCallback(async () => {
    queryClient.clear();
    // @ts-ignore We do not need to pass any parameters,
    // but TypeScript tells us that this method requires parameters
    dispatch(fetchCreator.rejected());
    clientApi.impersonatingId = null;
    window.sessionStorage.clear();

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return { setImpersonatedCreator, clearImpersonatedCreator };
};

export default useImpersonateCreator;
