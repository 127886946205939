import { FEATURE_FLAGS } from '~/constants';
import { useFeature } from '~/hooks/useFeatureFlag/useFeature';

export interface IFeatureProps {
  children: React.ReactNode;
  name: FEATURE_FLAGS;
}

export const Feature: React.FC<IFeatureProps> = ({ children, name }) => {
  const { isEnabled } = useFeature(name);

  if (isEnabled) {
    return <>{children}</>;
  }

  return null;
};
