import { Leva, useControls } from 'leva';
import { useEffect, useMemo } from 'react';
import { FEATURE_FLAGS } from '~/constants';
import { useUserSettings } from '~/hooks/useUserSettings.ts';

import { useFlagStore } from './store';

const allFlags = Object.values(FEATURE_FLAGS);

export const FeatureFlagController = () => {
  const featureFlags = useUserSettings();
  const { flags, setFlag, setStatus } = useFlagStore();

  useEffect(() => {
    setStatus(featureFlags.status);
  }, [featureFlags.status, setStatus]);

  const flagsAsControls = useMemo(() => {
    if (!featureFlags.data) return {};

    // Convert flags array to leva-compatible object
    return allFlags.reduce((acc, flagName) => {
      return {
        ...acc,
        [flagName]: {
          value: featureFlags.data.enabled_features.includes(flagName),
          onChange: (v: boolean) => {
            setFlag(flagName, v);
          },
        },
      };
    }, {});
  }, [featureFlags.data, setFlag]);

  useControls('Feature Flags', flagsAsControls, [flagsAsControls]) as Record<
    FEATURE_FLAGS,
    boolean
  >;

  const isFlagsUIEnabled = flags.foam_feature_flag_controller.value;

  // TODO: Consider removing env variable dependency: NEXT_PUBLIC_APP_ENV
  const isLevaHidden =
    !isFlagsUIEnabled || process.env.NEXT_PUBLIC_APP_ENV !== 'development'; // forcing this to limit only to dev environments

  return (
    <Leva
      hidden={isLevaHidden}
      collapsed
      hideCopyButton
      theme={{
        sizes: {
          controlWidth: '2rem',
        },
      }}
    />
  );
};
