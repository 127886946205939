import { AxiosInstance } from 'axios';

import { IAssetsUploadOptions, IAssetsUploadResponse } from './assets.model';

const MODULE = 'assets';

const assets = (apiInstance: AxiosInstance) => {
  const upload = ({
    file,
    type,
  }: IAssetsUploadOptions): Promise<IAssetsUploadResponse> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    return apiInstance.post(MODULE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  return {
    upload,
  };
};
export default assets;
