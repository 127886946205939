import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';
import authApi from '~/authApi';
import clientApi from '~/clientApi';
import { Metrics } from '~/clientApi/metrics';
import { captureException } from '~/sentry';

type Context = Metrics;

export const MetricsContext = createContext<Context>({} as Metrics);

export interface IMetricsProviderProps {
  children: React.ReactNode;
}

export const MetricsProvider: React.FC<IMetricsProviderProps> = ({
  children,
}) => {
  const [metrics, setMetrics] = useState<Metrics>({} as Metrics);
  const router = useRouter();

  const getUserMetrics = React.useCallback(async () => {
    // return when there is no active session
    if (!authApi.user?.id) return;

    try {
      const response = await clientApi.getUserMetrics();

      if (response.success) {
        setMetrics(response.metrics);
      }
    } catch (error) {
      captureException(error);
    }
  }, [setMetrics]);

  useEffect(() => {
    getUserMetrics();
  }, [getUserMetrics, router.pathname]);

  return (
    <MetricsContext.Provider value={metrics}>
      {children}
    </MetricsContext.Provider>
  );
};
