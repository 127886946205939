import Script from 'next/script';
import React from 'react';
import { FEATURE_FLAGS } from '~/constants';

import { Feature } from '../Feature';

// https://help.heap.io/getting-started/installation-guides/web/
export const AppHeapScript: React.FC = () => {
  if (!process.env.NEXT_PUBLIC_HEAP_KEY) {
    return null;
  }

  if (
    ['development', 'custom'].includes(process.env.NEXT_PUBLIC_BRANCH ?? '')
  ) {
    return (
      <Feature name={FEATURE_FLAGS.foamHeapDev}>
        <Script
          id="heap"
          // This is required for it to be loaded from the _app, since this is dynamically added later during the render as this is loaded via FeatureFlag
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
          window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load(${process.env.NEXT_PUBLIC_HEAP_KEY});
          `,
          }}
        />
      </Feature>
    );
  }

  return null;
};
