import { Box, Flex, FlexProps, useTheme } from '@chakra-ui/react';
import { FoamSpinner } from '~/components/FoamSpinner';

interface Props extends FlexProps {
  isVisible?: boolean;
}

export const AppLoader: React.FC<Props> = ({ isVisible = true, ...props }) => {
  const { zIndices } = useTheme();

  return (
    <Box
      opacity={isVisible ? 1 : 0}
      transition="opacity 250ms linear"
      pointerEvents={isVisible ? 'all' : 'none'}
    >
      <Flex
        aria-label="Loading app..."
        data-testid="app-loader"
        position="absolute"
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
        zIndex={zIndices.foamLoader}
        backgroundColor="neutral.100"
        {...props}
      >
        <FoamSpinner />
      </Flex>
    </Box>
  );
};
