import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import authApi from '~/authApi';
import { USER_ROLES } from '~/constants';
import { selectUser } from '~/selectors/user';

export const HeapInitializer = () => {
  const { roles } = authApi.user;
  const user = useSelector(selectUser);

  useEffect(() => {
    const impersonatorId = window.localStorage.getItem('impersonatorId');
    if (window.heap && user?.id && !roles.includes(USER_ROLES.anonymous)) {
      // We are going to send only te roles because is the only
      // property that BE cannot retrieve
      const userProperties = {
        role: roles,
      };
      window.heap.identify(user.id);
      window.heap.addUserProperties(userProperties);
      window.heap.clearEventProperties(); // Call this to clear any previously set properties in cookies https://developers.heap.io/reference/cleareventproperties
      window.heap.addEventProperties({ impersonatorId }); // Add Admin impersonator id to all events
    }
  }, [roles, user?.id]);

  return null;
};
