import { Box, Heading as CHeading, Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';
import { Footer } from 'ui-components';

export interface IHeadingProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface ISubheadingProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface ITextProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface IImageProps {
  accentColor: string;
  srcSet: string;
}

export interface IMainContentProps {
  children: React.ReactNode | React.ReactNode[];
}

const Heading: React.FC<IHeadingProps> = ({ children }) => (
  <CHeading fontSize="6xl" fontWeight="500" marginBottom={4}>
    {children}
  </CHeading>
);

const Subheading: React.FC<ISubheadingProps> = ({ children }) => (
  <CHeading
    as="h2"
    size="md"
    fontWeight="normal"
    color="primary.400"
    marginBottom={10}
  >
    {children}
  </CHeading>
);

const Text: React.FC<ITextProps> = ({ children }) => (
  <Box
    backgroundColor="gray.50"
    color="primary.400"
    paddingX={5}
    paddingY={4}
    fontSize="md"
    rounded="lg"
    marginBottom={6}
  >
    {children}
  </Box>
);

const Image: React.FC<IImageProps> = ({ srcSet, accentColor }) => (
  <Box position="absolute" right="10px" top="10%" zIndex="hide">
    <Box
      as="img"
      srcSet={srcSet}
      alt="Error"
      rounded="full"
      position="absolute"
      top="60%"
      right="50%"
      display={{ base: 'none', md: 'block' }}
    />
    <Box
      rounded="full"
      backgroundColor={accentColor}
      height="30px"
      width="30px"
    />
    <Box
      rounded="full"
      backgroundColor={accentColor}
      height="440px"
      width="440px"
    />
  </Box>
);

const MainContent: React.FC<IMainContentProps> = ({ children }) => (
  <Box position="relative">{children}</Box>
);

export interface IErrorPageProps {
  children: React.ReactNode | React.ReactNode[];
  containerProps?: FlexProps;
  noFooter?: boolean;
}

export interface IErrorPage extends React.FC<IErrorPageProps> {
  Heading: React.FC<IHeadingProps>;
  Image: React.FC<IImageProps>;
  MainContent: React.FC<IMainContentProps>;
  Subheading: React.FC<ISubheadingProps>;
  Text: React.FC<ITextProps>;
}

const ErrorPage: IErrorPage = ({
  children,
  containerProps = {},
  noFooter = false,
}) => {
  return (
    <Flex
      height={{ md: '100%' }}
      minHeight="100vh"
      flexDirection="column"
      position="relative"
      {...containerProps}
    >
      <Box flex="1">
        <Box
          paddingX={{ base: 6, md: 24 }}
          paddingRight={{ base: 6, md: 'calc(50% - 6rem)' }}
          paddingY={{ base: 12, md: 40 }}
          height="100%"
        >
          {children}
        </Box>
      </Box>
      {!noFooter && <Footer />}
    </Flex>
  );
};

ErrorPage.Heading = Heading;
ErrorPage.Image = Image;
ErrorPage.MainContent = MainContent;
ErrorPage.Subheading = Subheading;
ErrorPage.Text = Text;

export default ErrorPage;
