import cookie from 'js-cookie';
import jwtdecode from 'jwt-decode';
import { useEffect } from 'react';
import { Sentry } from '~/sentry';

export function useSentrySetUser() {
  const accessToken = cookie.get('accessToken');
  const jwtUser = accessToken && jwtdecode(accessToken);

  useEffect(() => {
    Sentry.setUser({
      id: jwtUser?.sub,
      email: jwtUser?.email,
      ip_address: '{{auto}}',
    });
  }, [jwtUser?.sub, jwtUser?.email]);
}
