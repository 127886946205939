import { UseToastOptions } from '@chakra-ui/react';

export const LIST_TOAST_CREATED = (name: string): UseToastOptions => ({
  title: 'List created',
  description: `The list ${name} has been created`,
  status: 'success',
  duration: 9000,
  isClosable: true,
});

export const LIST_TOAST_UPDATED: UseToastOptions = {
  title: 'List updated',
  description: 'The list has been updated',
  status: 'success',
  duration: 9000,
  isClosable: true,
};
