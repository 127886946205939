import qs from 'query-string';
import { ApiModule } from '~/clientApi/ApiModule';

const MODULE = 'contents';

const content = (apiInstance) => {
  const extraEndpoints = {
    share: `POST ${MODULE}/:id/share`,
    removePassword: `DELETE ${MODULE}/:id/share/password`,
    multiple: {
      url: `${MODULE}`,
      paramsSerializer: function ({ ids, ...params }) {
        const contentIds = ids.join(',');

        let query = qs.stringify(params);
        if (ids) {
          query += `&ids=${contentIds}`;
        }
        return query;
      },
    },
    pdfList: `GET ${MODULE}/list-pdf`,
  };

  return new ApiModule(apiInstance, MODULE, extraEndpoints);
};

export default content;
