import axios, { AxiosInstance } from 'axios';
import { ApiModule } from '~/clientApi/ApiModule';

import { CreatorApiModule } from './creator.model';

const MODULE = 'creators';

const defaultTransformRequest = Array.isArray(axios.defaults.transformRequest)
  ? axios.defaults.transformRequest
  : [];

const creators = (apiInstance: AxiosInstance) => {
  const extraEndpoints = {
    assignPrimaryManager: {
      method: 'PATCH',
      transformRequest: [
        ({ newManager }: { newManager: string }) => ({ newManager }),
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:creatorId/assign-manager`,
    },
    sendAuthReminder: `POST ${MODULE}/:id/send-auth-reminder`,
    removeAgencyManagers: {
      method: 'PATCH',
      transformRequest: [
        ({ managerIds }: { managerIds: string[] }) => ({ managerIds }),
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:creatorId/remove-agency-managers`,
    },
    sync: `GET ${MODULE}/:id/sync`,
    bulkImportCreators: {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${MODULE}/import`,
    },
  };

  return new ApiModule(apiInstance, MODULE, extraEndpoints) as CreatorApiModule;
};

export default creators;
