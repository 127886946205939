import axios from 'axios';
import { VersioningStrategy, withVersioning } from 'axios-api-versioning';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import cookie from 'js-cookie';
import jwtdecode from 'jwt-decode';
import { MAINTENANCE_MODE_EVENT } from '~/components/MaintenanceModeInterceptor/MaintenanceModeInterceptor.constants';
import {
  HEADER_NAME_API_VERSION,
  LOCALSTORAGE_KEY_IMPERSONATOR_ID,
  LOCALSTORAGE_KEY_LOGOUT,
  USER_ROLES,
} from '~/constants';
import { routes } from '~/core/router';
import { sendLogoutToChromeExtension } from '~/extensionUtils';
import {
  areEqualMajorVersions,
  getAndCleanImpersonatedParams,
  getEpochDate,
} from '~/utils';

/**
 * @typedef {Object} JWTAccessToken
 * @property {String} accessToken - JWT for logged in user
 * @property {String} refreshToken - Secondary token to refresh access one
 * @property {Number} expiresIn - Seconds until access token expires
 * @property {Number} refreshExpiresIn - Seconds until refresh token expires
 * @property {String} tokenType
 * @property {String} scope
 */

/**
 * @typedef {Object} JWTUser
 * @property {String} [id]
 * @property {String} [email]
 * @property {Boolean} [email_verified]
 * @property {USER_ROLES[]} roles
 * @property {string} invitationCode
 * @property {import('~/constants').USER_PERMISSIONS} permissions
 */
class AuthApi {
  constructor() {
    const baseClient = axios.create({
      baseURL: `${process.env.NEXT_PUBLIC_AUTH_RESOURCE}/v{apiVersion}`,
    });

    this._apiInstance = withVersioning(baseClient, {
      apiVersion: '1',
      versioningStrategy: VersioningStrategy.UrlPath,
    });

    this._apiInstance.interceptors.request.use((req) => {
      // include access token in the header for every request (if available)
      const accessToken = this.accessToken;

      if (accessToken) {
        req.headers['Authorization'] = `Bearer ${accessToken}`;
      } else {
        delete req.headers['Authorization'];
      }

      const { _impersonateOnce } = getAndCleanImpersonatedParams(req);
      if (_impersonateOnce) {
        req.headers['X-AUTH-IMPERSONATE'] = _impersonateOnce;
      }

      // force trailing slash on urls
      if (!req.url.endsWith('/')) {
        req.url += '/';
      }

      return req;
    });

    this._apiInstance.interceptors.response.use(({ data, headers = {} }) => {
      const apiMajorVersion =
        headers[HEADER_NAME_API_VERSION] ||
        headers[HEADER_NAME_API_VERSION.toLowerCase()];

      if (apiMajorVersion) {
        const gitTag = process.env.NEXT_PUBLIC_GIT_TAG;

        // Because this interceptor means that the other interceptors
        // in the whole application
        // are only receiving the data,
        // and not all the Axios configuration,
        // and we need this axios config to access headers
        window.dispatchEvent(
          new CustomEvent(MAINTENANCE_MODE_EVENT, {
            detail: {
              isUnderMaintenance: !areEqualMajorVersions(
                gitTag,
                apiMajorVersion,
              ),
            },
          }),
        );
      }

      // return response data rather than response itself
      return data;
    });

    // this will be run whenever there is a 401
    createAuthRefreshInterceptor(this._apiInstance, (failedRequest) => {
      return authApi.refreshAccessToken().then((newToken) => {
        failedRequest.response.config.headers['Authorization'] =
          `Bearer ${newToken?.accessToken}`;

        return Promise.resolve();
      });
    });
  }

  /**
   * JWT access token getter.
   * @returns {?JWTAccessToken}
   */
  get jwtAccessToken() {
    const accessToken = cookie.get('accessToken');
    const refreshToken = cookie.get('refreshToken');

    if (accessToken && refreshToken) {
      return {
        accessToken,
        refreshToken,
      };
    }

    return null;
  }

  /**
   * JWT access token setter.
   *
   * It saves the new token within the authApi instance but also in cookies.
   *
   * @param {?JWTAccessToken} newToken
   */
  set jwtAccessToken(newToken) {
    // TODO: we probably need to save something else or in a different way as
    //  with this current mechanism we only save access and refresh token but
    //  we are losing everything else
    if (newToken?.accessToken && newToken?.refreshToken) {
      const { accessToken, refreshToken } = newToken;
      const { exp } = jwtdecode(refreshToken);
      const expires = getEpochDate(exp);

      cookie.set('accessToken', accessToken, { expires });
      cookie.set('refreshToken', refreshToken, { expires });
    } else {
      cookie.remove('accessToken');
      cookie.remove('refreshToken');
    }
  }

  /**
   *
   * @returns {String|null} Access token if any or null
   */
  get accessToken() {
    return this.jwtAccessToken?.accessToken ?? null;
  }

  /**
   * Returns the full user if accessToken available.
   * Only with roles = ['anonymous'] otherwise.
   * @returns {JWTUser}
   */
  get user() {
    const accessToken = this.accessToken;
    let user = {
      roles: [USER_ROLES.anonymous],
    };

    if (accessToken) {
      try {
        const jwtUser = jwtdecode(accessToken);
        user.id = jwtUser?.sub;
        user.email = jwtUser?.email;
        user.email_verified = jwtUser?.email_verified;
        user.roles = jwtUser?.realm_access?.roles ?? [USER_ROLES.anonymous];
        user.permissions =
          jwtUser?.resource_access?.['realm-management']?.roles ?? [];
        user.invitationCode = jwtUser.sonar?.invitationCode ?? undefined;
      } catch {
        this.logout({ redirectToSSOLogout: true });
      }
    }

    return user;
  }

  /**
   * Sign up registration step.
   * @param {Object} args
   * @param {{email: string; password: string; recaptcha: string; agencyName?: string; managerName?: string; role: string}} args
   * @returns {Promise<string>}
   */
  signUp(args) {
    return this._apiInstance
      .post('/account/register', args)
      .then((newToken) => {
        this.jwtAccessToken = newToken;
        return newToken;
      });
  }

  /**
   * Link facebook account registration step.
   * @param {Object} args - Object containing FB account data, including `accountAccessToken`
   * @returns {Promise}
   */
  linkFacebookAccount(args) {
    return this._apiInstance.post('/account/facebook/access', args);
  }

  /**
   * Link Youtube account registration step.
   * @param {String} args.code Authorization Code
   * @returns {Promise}
   */
  linkYoutubeAccount(args) {
    return this._apiInstance.get('/account/youtube/access', { params: args });
  }

  /**
   * Link Tiktok account registration step.
   * @param {{code: string, scope: string}} args
   * @returns {Promise}
   */
  linkTiktokAccount(args) {
    return this._apiInstance.get('/account/tiktok/access', { params: args });
  }

  /**
   * Link Snapchat account.
   * @param {{code: string, _impersonateOnce?: string}} args
   * @returns {Promise}
   */
  linkSnapchatAccount(args) {
    return this._apiInstance.get('/account/snapchat/access', { params: args });
  }

  /**
   * Sends verification email
   * @param {Object} args
   * @param {String} args.email
   * @param {String} args.recaptcha
   * @returns {Promise}
   */
  sendVerifyEmail(args) {
    return this._apiInstance.post('/account/send-verify-email', args);
  }

  // TODO: This endpoint will coexist with `sendVerifyEmail`
  // (same, but `apiVersion: '1'` ) till the epic KRI-1805 gets deployed,
  // in which point we will keep this one (renamed or whatever is needed)
  // and get rid of `sendVerifyEmail`.
  /**
   * Sends verification email (requires authentication)
   * @returns {Promise}
   */
  sendVerifyEmailWithAuth() {
    return this._apiInstance.post(
      '/account/send-verify-email',
      {},
      {
        apiVersion: '2',
      },
    );
  }

  /**
   * Refreshes current access token for logged in user.
   * @returns {Promise<JWTAccessToken>} Promise fulfilling with access token
   */
  refreshAccessToken() {
    return this._apiInstance
      .post(
        '/token/refresh',
        {
          refreshToken: this.jwtAccessToken?.refreshToken,
        },
        {
          skipAuthRefresh: true,
        },
      )
      .then((newToken) => {
        this.jwtAccessToken = newToken;
        return newToken;
      })
      .catch(() => {
        // if refresh mechanism fails then we need to logout the user
        this.logout({ redirectToSSOLogout: true });
      });
  }

  /**
   * Logout user from the system.
   */
  async logout({ redirectToSSOLogout } = {}) {
    if (this.jwtAccessToken) {
      try {
        await this._apiInstance.post(
          '/account/logout/',
          {},
          {
            skipAuthRefresh: true,
          },
        );
      } catch (err) {
      } finally {
        this.jwtAccessToken = null;
      }
    }
    window.sessionStorage.clear();
    window.localStorage.removeItem(LOCALSTORAGE_KEY_IMPERSONATOR_ID);
    window.localStorage.setItem(LOCALSTORAGE_KEY_LOGOUT, String(Date.now()));
    sendLogoutToChromeExtension();
    if (redirectToSSOLogout) {
      window.location.assign(routes.logoutSSO);
    }
  }

  /**
   * Impersonate a user on the auth service.
   * @param {{subjectId: string, impersonatorId: string}} args
   * @returns {promise} fulfilled to a JWT that behaves like the original user's
   */
  impersonate({ impersonatorId, subjectId }) {
    return this._apiInstance
      .post(
        '/account/impersonate/',
        { subjectId },
        {
          skipAuthRefresh: true,
        },
      )
      .then((newToken) => {
        window.localStorage.setItem(
          LOCALSTORAGE_KEY_IMPERSONATOR_ID,
          impersonatorId,
        );
        this.jwtAccessToken = newToken;
        return newToken;
      });
  }

  /**
   * Update ManagedCreator role to Creator
   * @param {import('./authApi.vm').UserRoleOptions} options
   * @returns {Promise<JWTAccessToken>}
   */
  updateUserRole({ addRole, removeRole, userId }, updateAccessToken = true) {
    return this._apiInstance(`/account/roles/map`, {
      data: {
        add: addRole.join(','),
        delete: removeRole.join(','),
      },
      params: {
        userId,
      },
      method: 'PUT',
    }).then((newToken) => {
      if (updateAccessToken) {
        this.jwtAccessToken = newToken;
      }
      return newToken;
    });
  }
}

const authApi = new AuthApi();

export default authApi;
