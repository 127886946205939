import { useRouter } from 'next/router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authApi, { JWTAccessToken } from '~/authApi';
import clientApi from '~/clientApi';
import { AppLoader } from '~/components/AppLoader';
import { queryClient } from '~/config/reactQueryConfig';
import { LOCALSTORAGE_KEY_LOGOUT, USER_ROLES } from '~/constants';
import { IntercomContext } from '~/contexts/Intercom/IntercomContext';
import { routes } from '~/core/router';
import { generatePath } from '~/core/router/router.helpers';
import { getMainPlatformStatus } from '~/creatorUtils';
import { fetchCreator } from '~/ducks/creator';
import { fetchTalentManager } from '~/ducks/talent-manager';
import useImpersonateCreator from '~/hooks/useImpersonateCreator';
import { usePersistedState } from '~/hooks/usePersistedState';
import { selectCreator } from '~/selectors/creator';
import { selectTalentManager } from '~/selectors/talent-manager';
import { getUrlSearchParams, urlSearchParamsIncludes } from '~/utils-ts';

import { SKIP_RECONNECT_SESSION_KEY, STATUS } from './auth.constants';
import { mapURLSearchParamsToParams } from './auth.context.mapper';
import { hasTokensInUrl } from './auth.context.utils';
import {
  AuthTokens,
  ImpersonatePayload,
  LinkFacebookAccountPayload,
} from './auth.vm';

interface AuthContextInterface {
  acceptManagerInvite: (invitationId: string) => Promise<void>;
  getMainUrl: (settings?: { skipReconnect?: boolean }) => string;
  impersonate: (payload: ImpersonatePayload) => Promise<void>;
  logout: (params?: { redirectToSSOLogout?: boolean }) => Promise<void>;
  linkFacebookAccount: (
    payload: LinkFacebookAccountPayload,
    redirect: boolean,
  ) => Promise<void>;

  redirect: () => void;
  updateManagedCreatorRoleToCreator: (userId: string) => Promise<void>;
  skipReconnect: boolean;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

export interface IAuthProviderProps {
  children?: React.ReactNode;
  skipLoading?: boolean;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({
  skipLoading = false,
  ...props
}) => {
  const { shutdown } = React.useContext(IntercomContext);
  const [authStatus, setAuthStatus] = React.useState(
    skipLoading ? STATUS.ready : STATUS.loading,
  );
  const { setImpersonatedCreator } = useImpersonateCreator();
  const [creatorToImpersonate, setCreatorToImpersonate] = React.useState<any>();
  const [skipReconnect, setSkipReconnect] = usePersistedState(
    SKIP_RECONNECT_SESSION_KEY,
    false,
    typeof window === 'undefined' ? undefined : window.sessionStorage,
  );
  const creator = useSelector(selectCreator);
  const manager = useSelector(selectTalentManager);
  const dispatch = useDispatch();
  const router = useRouter();
  const { hasConnected, hasExpired, hasReAuth } =
    getMainPlatformStatus(creator);

  /** Add a listener to storage, so when logging out on one tab, all tabs log out*/
  React.useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === 'logout') {
        window.location.assign(routes.loginSSO);
      }
    };

    window.addEventListener('storage', syncLogout);
    return () => {
      window.removeEventListener('storage', syncLogout);
      window.localStorage.removeItem(LOCALSTORAGE_KEY_LOGOUT);
    };
  }, [router]);

  const acceptManagerInvite = async (invitationId: string) => {
    const { roles } = authApi.user;

    let jwtAccessToken: JWTAccessToken | undefined;

    /**
     * If the user has creator role we update it to
     * managed-creator, in case he already is we
     * will accept the invite with current user accessToken
     */
    if (roles.includes(USER_ROLES.creator)) {
      const updatedRole = [...authApi.user.roles, USER_ROLES.managed].filter(
        (role) => role !== USER_ROLES.creator,
      );

      setAuthStatus(STATUS.loading);

      // Store new jwt for next request.
      jwtAccessToken = await authApi.updateUserRole(
        {
          addRole: updatedRole,
          removeRole: [USER_ROLES.creator],
          userId: creator.id,
        },
        false,
      );
    }

    // Accept invite with either the accessToken or sharedToken in case we updated the role.
    const response = await clientApi.invitations.accept({
      id: invitationId,
      _sharedToken: jwtAccessToken?.accessToken,
    });

    if (jwtAccessToken) {
      // Update the jwtAccessToken in authApi and force redirect
      authApi.jwtAccessToken = jwtAccessToken;
    }

    return response;
  };

  const getMainUrl = React.useCallback(
    (settings?: { skipReconnect?: boolean }) => {
      const shouldSkipReconnect = settings?.skipReconnect
        ? settings.skipReconnect
        : skipReconnect;

      if (shouldSkipReconnect) {
        setSkipReconnect(true);
      }

      // If the user has echo-creator role and has not seen welcome screen we redirect
      if (
        creator?.id &&
        !authApi.user.roles.includes(USER_ROLES.talentManager) &&
        !creator?.echoWelcomeSeen &&
        authApi.user.roles.includes(USER_ROLES.echoCreator)
      ) {
        return routes.echoWelcomeScreen;
      }

      if (
        authApi.user.roles.includes(USER_ROLES.managed) &&
        !creator?.platformFullAccess
      ) {
        return routes.connectionDashboard;
      }

      if (
        authApi.user.roles.includes(USER_ROLES.creator) ||
        authApi.user.roles.includes(USER_ROLES.managed)
      ) {
        if (!hasConnected) {
          return routes.signUpConnectSocials;
        }

        const shouldReconnect = hasExpired || hasReAuth;

        if (!shouldSkipReconnect && shouldReconnect) {
          return routes.reconnectPlatforms;
        }

        if (creator.slug && creator?.manager?.id) {
          return routes.platformConnections;
        }
        return routes.mediaPacks;
      }

      if (authApi.user.roles.includes(USER_ROLES.talentManager)) {
        if (manager?.agency?.enabled) {
          return routes.myRoster;
        }
        return routes.myRoster;
      }

      return routes.logout;
    },
    [
      skipReconnect,
      creator,
      setSkipReconnect,
      hasConnected,
      hasExpired,
      hasReAuth,
      manager,
    ],
  );

  const fetchUserEntity = async () => {
    // if user is logged in...
    const { id, roles } = authApi.user;
    if (
      roles.includes(USER_ROLES.creator) ||
      roles.includes(USER_ROLES.managed)
    ) {
      // ...fetch corresponding creator...
      const fetchedCreator = await clientApi.creators.read(id);
      dispatch((fetchCreator as any).fulfilled(fetchedCreator));
    } else if (roles.includes(USER_ROLES.talentManager)) {
      const impersonatingId = clientApi.impersonatingId;
      // ...fetch corresponding talentManager...
      const fetchedTalentManager = await clientApi.managers.read({
        id,
        _isImpersonatingDisabled: true,
      });

      dispatch((fetchTalentManager as any).fulfilled(fetchedTalentManager));

      // ...fetch corresponding impersonated Creator...
      if (impersonatingId) {
        try {
          const fetchedCreator = await clientApi.creators.read(impersonatingId);
          dispatch((fetchCreator as any).fulfilled(fetchedCreator));
        } catch (error) {
          clientApi.impersonatingId = null;
        }
      }
    }
  };

  const logout = async (
    { redirectToSSOLogout } = { redirectToSSOLogout: true },
  ) => {
    setAuthStatus(STATUS.loading);
    clientApi.impersonatingId = null;

    queryClient.clear();
    if (window.heap) {
      window.heap.resetIdentity();
    }
    shutdown();
    await authApi.logout({ redirectToSSOLogout });
  };

  const redirect = async () => {
    setAuthStatus(STATUS.redirecting);
  };

  const impersonate = async (payload: ImpersonatePayload) => {
    await authApi.impersonate(payload);
    setAuthStatus(STATUS.loading);
    await fetchUserEntity();
    setAuthStatus(STATUS.redirecting);
  };

  const linkFacebookAccount = async (
    payload: LinkFacebookAccountPayload,
    redirect = true,
  ) => {
    const account = await authApi.linkFacebookAccount(payload);
    if (redirect) setAuthStatus(STATUS.loading);
    await fetchUserEntity();
    if (redirect) setAuthStatus(STATUS.redirecting);
    return account;
  };

  const updateManagedCreatorRoleToCreator = async (userId: string) => {
    let jwtAccessToken: JWTAccessToken | undefined;

    const updatedRole = Array.from(
      new Set([...authApi.user.roles, USER_ROLES.creator]),
    ).filter((role) => role !== USER_ROLES.managed);

    jwtAccessToken = await authApi.updateUserRole({
      addRole: updatedRole,
      removeRole: [USER_ROLES.managed],
      userId,
    });

    setAuthStatus(STATUS.loading);
    await fetchUserEntity();

    if (jwtAccessToken) {
      // Update the jwtAccessToken in authApi and force redirect
      authApi.jwtAccessToken = jwtAccessToken;
    }
  };

  const storeTokens = ({ refreshToken, accessToken }: AuthTokens) => {
    if (Boolean(accessToken) && Boolean(refreshToken)) {
      authApi.jwtAccessToken = {
        accessToken: accessToken,
        refreshToken: refreshToken,
      } as JWTAccessToken;
    }
  };

  React.useEffect(() => {
    const onUserIdChange = async () => {
      try {
        const hasTokens = hasTokensInUrl();

        if (
          hasTokens ||
          (authApi.user?.id && urlSearchParamsIncludes(['creatorId']))
        ) {
          const params = getUrlSearchParams();
          const {
            accessToken = '',
            creatorId = '',
            refreshToken = '',
            ...otherQueryParams
          } = mapURLSearchParamsToParams(params);

          const currentPathname = generatePath(window.location.pathname, {
            query: otherQueryParams,
          });

          setCreatorToImpersonate(creatorId);

          if (hasTokens) {
            // It is always assigned even if it already had one assigned
            storeTokens({
              accessToken: accessToken,
              refreshToken: refreshToken,
            });
          }

          if (creator && creator.id === creatorId) {
            return;
          }

          setAuthStatus(STATUS.loading);

          const isManager = authApi.user?.roles?.includes(
            USER_ROLES.talentManager,
          );
          if (
            (isManager && authApi.user?.id && Boolean(creatorId)) ||
            (!isManager && Boolean(creatorId))
          ) {
            await setImpersonatedCreator(
              {
                id: creatorId,
              },
              currentPathname,
            );
          }
        }
        await fetchUserEntity();
        setAuthStatus(STATUS.ready);
      } catch {
        setAuthStatus(STATUS.ready);
      }
    };

    onUserIdChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setImpersonatedCreator]);

  React.useEffect(() => {
    const redirectUser = async () => {
      try {
        if (authStatus === STATUS.redirecting) {
          const {
            query: { redirectTo },
          } = router;
          setAuthStatus(STATUS.loading);

          if (creatorToImpersonate) {
            const creator = await clientApi.creators.read({
              id: creatorToImpersonate,
            });

            await setImpersonatedCreator(creator);
            await router.replace(
              (redirectTo as string | undefined) || getMainUrl(),
            );
          } else {
            await router.replace(
              (redirectTo as string | undefined) || getMainUrl(),
            );
          }
          setAuthStatus(STATUS.ready);
        }
      } catch (error) {
        setAuthStatus(STATUS.ready);
      }
    };

    redirectUser();
  }, [
    creator,
    router,
    getMainUrl,
    authStatus,
    creatorToImpersonate,
    setImpersonatedCreator,
  ]);

  const providerValue = {
    acceptManagerInvite,
    getMainUrl,
    impersonate,
    linkFacebookAccount,
    logout,
    redirect,
    skipReconnect,
    updateManagedCreatorRoleToCreator,
  };
  return (
    <>
      <AppLoader isVisible={authStatus !== STATUS.ready} />
      {authStatus === STATUS.ready && (
        <AuthContext.Provider value={providerValue} {...props} />
      )}
    </>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
};
