const MODULE = 'locations';

const locations = (apiInstance) => {
  /**
   * API method to search locations
   * @param {Object} payload
   * @param {String} payload.search - Text to search
   * @returns {Promise}
   */
  const search = (payload) => {
    return apiInstance.post(`${MODULE}/search`, payload);
  };

  return {
    search,
  };
};

export default locations;
