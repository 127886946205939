import * as viewModel from 'creatorUtils.types';
import { ALL_SOCIAL_PLATFORMS, SOCIAL_PLATFORMS } from '~/constants';

import * as apiModel from './types';

const mapCreatorPlatformInstagramFromApiToVm = ({
  handle,
  isFirstGraphAuth = true,
  isGraphTokenValid = false,
  name,
  profilePicUrl,
  shouldGraphReAuth = false,
  graphFirstAuthAt,
}: Partial<
  apiModel.CreatorPlatformsType['platforms'][SOCIAL_PLATFORMS.instagram]
> = {}): viewModel.ICreatorPlatform => ({
  avatar: profilePicUrl,
  firstAuthAt: graphFirstAuthAt ? new Date(graphFirstAuthAt) : undefined,
  handle,
  isConnected: !isFirstGraphAuth,
  isTokenValid: isGraphTokenValid,
  name: name ?? handle,
  shouldReAuth: shouldGraphReAuth,
});

const mapCreatorPlatformTiktokFromApiToVm = ({
  isTiktokConnected = false,
  isTiktokTokenValid = false,
  name,
  profileImage,
  shouldTiktokReAuth = false,
  tiktokFirstAuthAt,
  tiktokHandle,
}: Partial<
  apiModel.CreatorPlatformsType['platforms'][SOCIAL_PLATFORMS.tiktok]
> = {}): viewModel.ICreatorPlatform => ({
  avatar: profileImage,
  firstAuthAt: tiktokFirstAuthAt ? new Date(tiktokFirstAuthAt) : undefined,
  handle: tiktokHandle,
  isConnected: isTiktokConnected,
  isTokenValid: isTiktokTokenValid,
  name: name || tiktokHandle,
  shouldReAuth: shouldTiktokReAuth,
});

const mapCreatorPlatformYoutubeFromApiToVm = ({
  isYoutubeConnected = false,
  isYoutubeTokenValid = false,
  shouldYtReAuth = false,
  youtubeFirstAuthAt,
}: Partial<
  apiModel.CreatorPlatformsType['platforms'][SOCIAL_PLATFORMS.youtube]
> = {}): viewModel.ICreatorPlatform => ({
  firstAuthAt: youtubeFirstAuthAt ? new Date(youtubeFirstAuthAt) : undefined,
  isConnected: isYoutubeConnected,
  isTokenValid: isYoutubeTokenValid,
  name: '',
  shouldReAuth: shouldYtReAuth,
});

const mapCreatorPlatformSnapchatFromApiToVm = ({
  isSnapchatConnected = false,
  isSnapchatTokenValid = false,
  displayName,
  profileUrl,
  shouldSnapchatReAuth = false,
  snapUserName,
}: Partial<
  apiModel.CreatorPlatformsType['platforms']['snapchat']
> = {}): viewModel.ICreatorPlatform => ({
  avatar: profileUrl,
  handle: snapUserName,
  isConnected: isSnapchatConnected,
  isTokenValid: isSnapchatTokenValid,
  name: displayName || snapUserName,
  shouldReAuth: shouldSnapchatReAuth,
});

export const mapCreatorPlatformsFromApiToVm = (
  platforms?: apiModel.CreatorPlatformsType['platforms'],
): viewModel.CreatorPlatformsType => ({
  [SOCIAL_PLATFORMS.instagram]: mapCreatorPlatformInstagramFromApiToVm(
    platforms?.instagram,
  ),
  [SOCIAL_PLATFORMS.tiktok]: mapCreatorPlatformTiktokFromApiToVm(
    platforms?.tiktok,
  ),
  [SOCIAL_PLATFORMS.youtube]: mapCreatorPlatformYoutubeFromApiToVm(
    platforms?.youtube,
  ),
  [ALL_SOCIAL_PLATFORMS.snapchat]: mapCreatorPlatformSnapchatFromApiToVm(
    platforms?.snapchat,
  ),
});
