import axios, { AxiosInstance } from 'axios';
import { ApiModule, Config } from '~/clientApi/ApiModule';

import { SavedListsApiModule } from './savedLists.model';

const MODULE = 'saved-lists';

const savedLists = (apiInstance: AxiosInstance) => {
  const defaultTransformRequest = Array.isArray(axios.defaults.transformRequest)
    ? axios.defaults.transformRequest
    : [];

  const extraEndpoints: Config = {
    addCreators: `POST ${MODULE}/:id/creators`,
    creators: `GET ${MODULE}/:id/creators`,
    removeCreators: `DELETE ${MODULE}/:id/creators`,
    updateCreator: {
      method: 'PUT',
      transformRequest: [
        function ({ id: _id, creatorId: _creatorId, ...data }: any) {
          return data;
        },
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:id/creators/:creatorId`,
    },
    createSharedLink: {
      method: 'POST',
      transformRequest: [
        function ({ entityId: _entityId, ...data }: any) {
          return data;
        },
        ...defaultTransformRequest,
      ],
      url: `${MODULE}/:entityId/share-links`,
    },
  };

  return new ApiModule(
    apiInstance,
    MODULE,
    extraEndpoints,
  ) as SavedListsApiModule;
};

export default savedLists;
