import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clientApi from '~/clientApi';

export const fetchTalentManagerHandler = async (payload) => {
  const { id } = payload;
  let talentManager;

  talentManager = await clientApi.managers.read(id);
  return talentManager;
};

export const fetchTalentManager = createAsyncThunk(
  'talent-manager/fetchTalentManager',
  fetchTalentManagerHandler,
);
const talentManagerSlice = createSlice({
  name: 'talent-manager',
  initialState: null,
  extraReducers: {
    [fetchTalentManager.fulfilled]: (state, action) => {
      return action.payload;
    },
    [fetchTalentManager.rejected]: () => {
      return null;
    },
  },
});

export default talentManagerSlice.reducer;
