import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Button from '~/components/Button';
import ErrorPage from '~/components/ErrorPage';
import LinkButton from '~/components/LinkButton';
import { Sentry } from '~/sentry';

const propTypes = {
  errorEventId: PropTypes.string,
};

const Error500 = ({ errorEventId }) => {
  return (
    <ErrorPage>
      <ErrorPage.MainContent>
        <ErrorPage.Heading>Something went wrong…</ErrorPage.Heading>
        <ErrorPage.Subheading>
          Sorry, something went wrong. We are working to fix this error
        </ErrorPage.Subheading>
        <ErrorPage.Text>
          Try to reload the page, or check back later. <br />
          {!!errorEventId && (
            <Text>
              If the problem persists, please{' '}
              <LinkButton
                verticalAlign="baseline"
                colorScheme="primary"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: errorEventId })
                }
              >
                report the error to us.
              </LinkButton>
            </Text>
          )}
        </ErrorPage.Text>

        <Button as="a" href="/" colorScheme="primary">
          GO TO HOME PAGE
        </Button>
      </ErrorPage.MainContent>
      <ErrorPage.Image
        accentColor="#DDF3C9"
        srcSet="/images/500_img.jpg, /images/500_imgx2.jpg 2x, /images/500_imgx3.jpg 3x"
      />
    </ErrorPage>
  );
};

Error500.propTypes = propTypes;

export default Error500;
