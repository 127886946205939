export enum NOTIFICATION_TYPES {
  adContent = 'AD_CONTENT_POSTED',
  contentOutlierHigh = 'CONTENT_OUTLIER_HIGH',
  contentOutlierLow = 'CONTENT_OUTLIER_LOW',
  creatorBirthday = 'CREATOR_BIRTHDAY',
  creatorBirthdayPrior = 'CREATOR_BIRTHDAY_PRIOR',
  followersMilestone = 'FOLLOWERS_MILESTONE',
  platformConnected = 'PLATFORM_CONNECTED',
  platformDisconnected = 'PLATFORM_DISCONNECTED',
  sharedCreator = 'SHARED_CREATORS',
  unshareCreators = 'UNSHARE_CREATORS',
}

export const NOTIFICATION_SUPPORTED_TYPES: NOTIFICATION_TYPES[] = [
  NOTIFICATION_TYPES.adContent,
  NOTIFICATION_TYPES.contentOutlierHigh,
  NOTIFICATION_TYPES.contentOutlierLow,
  NOTIFICATION_TYPES.creatorBirthday,
  NOTIFICATION_TYPES.creatorBirthdayPrior,
  NOTIFICATION_TYPES.followersMilestone,
  NOTIFICATION_TYPES.platformConnected,
  NOTIFICATION_TYPES.platformDisconnected,
  NOTIFICATION_TYPES.sharedCreator,
  NOTIFICATION_TYPES.unshareCreators,
];

export const NOTIFICATIONS_TYPES_TO_MARK_AS_READ = NOTIFICATION_SUPPORTED_TYPES;
