import dayjs from 'dayjs';
import 'dayjs/locale/en';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import badMutable from 'dayjs/plugin/badMutable';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
import isMoment from 'dayjs/plugin/isMoment';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import Router from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import 'react-cmdk/dist/cmdk.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { AppProviders } from '~/components/AppProviders';
import { FeatureFlagController } from '~/components/FeatureFlagControls';
import Head from '~/components/Head';
import { AppHeapScript } from '~/components/HeapScript';
import facebookApi from '~/facebookApi';
import '~/styles/intercom.css';
import '~/styles/nprogress.css';
import '~/styles/react-dates-overrides.css';

dayjs.extend(advancedFormat);
dayjs.extend(badMutable);
dayjs.extend(customParseFormat);
dayjs.extend(isLeapYear);
dayjs.extend(isMoment);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(isBetween);
dayjs.locale('en');

Router.events.on('routeChangeStart', () => {
  NProgress.start();
  document.body.style = 'cursor: progress';
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
  document.body.style = 'cursor: initial';
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
  document.body.style = 'cursor: initial';
});

if (typeof window !== 'undefined') {
  facebookApi.init();
}

const propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
};

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    if (typeof window && window.navigator.language) {
      dayjs.locale(window.navigator.language ?? 'en');
    }
  }, []);

  return (
    <AppProviders
      isSSR={pageProps?.isSSR}
      dehydratedState={pageProps?.dehydratedState}
    >
      <Head />
      {process.env.NEXT_PUBLIC_DATADOG_APP_ID &&
        process.env.NEXT_PUBLIC_DATADOG_TOKEN &&
        process.env.NEXT_PUBLIC_DATADOG_ENV && (
          <Script
            id="datadog-rum"
            src="https://www.datadoghq-browser-agent.com/eu1/v5/datadog-rum.js"
            onLoad={() =>
              window.DD_RUM.onReady(function () {
                window.DD_RUM.init({
                  applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
                  clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
                  env: process.env.NEXT_PUBLIC_DATADOG_ENV,
                  version: process.env.NEXT_PUBLIC_GIT_TAG,
                  site: 'datadoghq.eu',
                  service: 'foam',
                  sessionSampleRate: 100,
                  sessionReplaySampleRate: 20,
                  trackUserInteractions: true,
                  trackResources: true,
                  trackLongTasks: true,
                  defaultPrivacyLevel: 'mask-user-input',
                });
              })
            }
          />
        )}
      <AppHeapScript />
      <Component {...pageProps} />
      <FeatureFlagController />
    </AppProviders>
  );
};

App.propTypes = propTypes;

export default App;
