import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clientApi from '~/clientApi';

export const fetchCreatorHandler = async (payload) => {
  const { isAnonymousUser = false, id, sharedToken } = payload;
  let creator;
  if (isAnonymousUser) {
    // If anonymous user then get all creators as they'll be filtered by just
    // those available for anonymous token. It must return list of 1 creator.
    const { results } = await clientApi.creators.index();
    [creator] = results;
  } else {
    // If logged user then get specific creator related to them
    // and use the provided sharedToken if available.
    creator = await clientApi.creators.read({
      id: id,
      _sharedToken: sharedToken || clientApi._sharedToken,
    });
  }
  return creator;
};

export const fetchCreator = createAsyncThunk(
  'creator/fetchCreator',
  fetchCreatorHandler,
);
const creatorSlice = createSlice({
  name: 'creator',
  initialState: null,
  extraReducers: {
    [fetchCreator.fulfilled]: (state, action) => {
      return action.payload;
    },
    [fetchCreator.rejected]: () => {
      return null;
    },
  },
});

export default creatorSlice.reducer;
