import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Menu: ComponentMultiStyleConfig = {
  parts: ['item', 'list'],
  baseStyle: {
    list: {
      boxShadow: 'none',
      minWidth: 'inherit',
      border: 'none',
      borderRadius: 'xl',
      whiteSpace: 'nowrap',
      // Fixes boxShadow disappearing issue
      _before: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxShadow: 'menu',
        borderRadius: 'xl',
        pointerEvents: 'none',
        content: '""',
      },
    },
    item: {
      _focus: {
        background: 'none',
      },
      _hover: {
        background: 'primary.50',
      },
      color: 'neutral.800',
      fontSize: 'md',
      fontWeight: 500,
    },
  },
  variants: {
    select: {
      button: {
        borderColor: 'primary.100',
        borderRadius: 'full',
        borderStyle: 'solid',
        borderWidth: '1px',
        outlineOffset: 0,
        paddingX: 5,
        _hover: {
          borderColor: 'primary.500',
          outlineColor: 'primary.100',
          outlineStyle: 'solid',
          outlineWidth: '4px',
        },
        _active: {
          borderColor: 'secondary.500',
          outlineColor: 'secondary.50',
          outlineStyle: 'solid',
          outlineWidth: '4px',
        },
      },
      list: {
        padding: 2,
      },
      item: {
        marginY: 2,
        fontWeight: 400,
        _checked: {
          fontWeight: 500,
        },
        _hover: {
          backgroundColor: 'primary.50',
          borderRadius: 'md',
          fontWeight: 500,
        },
        _disabled: {
          _hover: {
            background: 'none',
            fontWeight: 400,
          },
        },
      },
    },
  },
  sizes: {
    xs: {
      button: {
        height: 6,
        minHeight: 6,
      },
    },
    sm: {
      button: {
        height: 8,
        minHeight: 8,
      },
    },
    md: {
      button: {
        height: 10,
        minHeight: 10,
      },
    },
    lg: {
      button: {
        height: 12,
        minHeight: 12,
      },
    },
  },
};
