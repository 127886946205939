import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiEndpoint } from 'axios-actions';

export type Config = {
  [key: string]: AxiosRequestConfig | string;
};

/**
 * Custom class to generate axios module following the usual REST shape.
 *
 * The default REST shape is:
 * - index: GET /:resource
 * - create: POST /:resource
 * - read: GET /:resource/:id
 * - update: PATCH /:resource/:id
 * - delete: DELETE /:resource/:id
 * - replace: PUT /:resource/:id
 *
 * This default shape can be override or extended with a custom config.
 *
 * More info:
 * https://github.com/davestewart/axios-actions/blob/master/docs/classes/ApiEndpoint.md#config
 */
export class ApiModule extends ApiEndpoint {
  constructor(
    axiosInstance: AxiosInstance,
    moduleName: string,
    customConfig?: Config,
  ) {
    const defaultTransformRequest = Array.isArray(
      axios.defaults.transformRequest,
    )
      ? axios.defaults.transformRequest
      : [];

    const finalConfig: Config = {
      index: moduleName,
      create: moduleName,
      read: `${moduleName}/:id`,
      update: {
        method: 'PATCH',
        url: `${moduleName}/:id`,
        headers: { 'Content-Type': 'application/merge-patch+json' },
        transformRequest: [
          // strip out id from body
          function ({ id: _, ...data }) {
            return data;
          },
          ...defaultTransformRequest,
        ],
      },
      replace: `PUT ${moduleName}/:id`,
      delete: `DELETE ${moduleName}/:id`,
    };

    Object.assign(finalConfig, customConfig);

    super(axiosInstance, finalConfig);
  }
}
