import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentMultiStyleConfig = {
  parts: ['tab', 'tablist'],
  baseStyle: {
    tab: {
      fontWeight: 400,
      _active: {
        backgroundColor: 'unset',
      },
      _selected: {
        fontWeight: 500,
      },
    },
  },
  variants: {
    // This is the default variant, we're using when omitting the `variant`.
    // Is very important to know this, because if we use only `baseStyle` some
    // styles won't be applied https://github.com/chakra-ui/chakra-ui/issues/5295.
    line: {
      tab: {
        _hover: {
          color: 'primary.600',
        },
        _selected: {
          backgroundColor: 'unset',
          borderBottomColor: 'secondary.500',
          borderRadius: 0,
          color: 'primary.500',
        },
        backgroundColor: 'unset',
        borderBottomColor: 'transparent',
        borderBottomStyle: 'solid',
        borderBottomWidth: '4px',
        color: 'primary.300',
        flexDirection: 'column',
        fontSize: { base: 'sm', md: 'sm', lg: 'md' },
        lineHeight: '14px',
        marginBottom: '-1px',
        paddingX: '12',
        textTransform: 'uppercase',
      },
      tablist: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
      },
    },
    'vertical-right-bordered': {
      tab: {
        borderInlineColor: 'transparent',
        borderInlineEndWidth: 4,
        borderInlineEndStyle: 'solid',
        color: 'primary.300',
        justifyContent: 'start',
        textAlign: 'start',
        _hover: {
          color: 'primary.500',
        },
        _selected: {
          backgroundColor: 'unset',
          borderRadius: 0,
          borderColor: 'secondary.500',
          color: 'primary.500',
        },
      },
      tablist: {
        borderColor: 'primary.100',
        borderInlineEndWidth: 1,
        borderInlineEndStyle: 'solid',
        borderInlineStartWidth: 0,
        borderInlineStartStyle: 'none',
        paddingBottom: 8,
        paddingTop: 8,
      },
    },
  },
};
