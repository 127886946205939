import { AxiosInstance } from 'axios';
import { ApiModule } from '~/clientApi/ApiModule';

import { ExtendedApiModule } from './clientApi.model';
import {
  INotificationOptions,
  NotificationSummary,
} from './notifications.model';

interface NotificationApiModule
  extends Omit<ExtendedApiModule<keyof typeof extraEndpoints>, 'summary'> {
  index: (options?: INotificationOptions) => Promise<any>;
  summary: (options?: INotificationOptions) => Promise<NotificationSummary[]>;
  markTypeAsRead: (options: INotificationOptions) => Promise<any>;
}

const MODULE = 'notifications';

const extraEndpoints = {
  markTypeAsRead: `POST ${MODULE}/:type/read`,
  markAsRead: `POST ${MODULE}/single/:id/read`,
  summary: `GET ${MODULE}/summary`,
};

const notifications = (apiInstance: AxiosInstance) =>
  new ApiModule(apiInstance, MODULE, extraEndpoints) as NotificationApiModule;

export default notifications;
