import { CreatorPlatformSnapchat } from './clientApi/creator.model';
import {
  HANDLE_PREFIX,
  PRIMARY_SOCIAL_PLATFORMS,
  SOCIAL_PLATFORMS,
  SOCIAL_PLATFORMS_CONNECTION_STATUS,
} from './constants';
import { mapCreatorPlatformsFromApiToVm } from './creatorUtils.mapper';
import {
  ICreatorPlatformsStatus,
  ICreatorPrimaryPlatformData,
  ICreatorPrimaryPlatformsStatus,
} from './creatorUtils.types';
import {
  CreatorPlatformInstagramType,
  CreatorPlatformTiktokType,
  CreatorPlatformYoutubeType,
  CreatorType,
} from './types';

export const getCreatorInstagramData = (
  creator?: CreatorType,
): CreatorPlatformInstagramType =>
  creator?.platforms?.instagram || ({} as CreatorPlatformInstagramType);

export const getCreatorInstagramHandle = (
  creator?: CreatorType,
): string | undefined =>
  creator ? getCreatorInstagramData(creator).handle : undefined;

export const getCreatorYoutubeData = (
  creator?: CreatorType,
): CreatorPlatformYoutubeType =>
  creator?.platforms?.youtube || ({} as CreatorPlatformYoutubeType);

export const getCreatorTiktokData = (
  creator?: CreatorType,
): CreatorPlatformTiktokType =>
  creator?.platforms?.tiktok || ({} as CreatorPlatformTiktokType);

export const getCreatorTiktokHandle = (
  creator?: CreatorType,
): string | undefined =>
  creator ? getCreatorTiktokData(creator).tiktokHandle : undefined;

export const getCreatorSnapchatData = (
  creator?: CreatorType,
): CreatorPlatformSnapchat =>
  creator?.platforms?.snapchat || ({} as CreatorPlatformSnapchat);

export const getCreatorConnectedPlatforms = (creator?: CreatorType) => {
  if (!creator) return [];

  const platforms = [];

  if (
    getCreatorInstagramData(creator).status ===
    SOCIAL_PLATFORMS_CONNECTION_STATUS.connected
  ) {
    platforms.push(SOCIAL_PLATFORMS.instagram);
  }

  if (
    getCreatorTiktokData(creator).status ===
    SOCIAL_PLATFORMS_CONNECTION_STATUS.connected
  ) {
    platforms.push(SOCIAL_PLATFORMS.tiktok);
  }

  if (
    getCreatorYoutubeData(creator).status ===
    SOCIAL_PLATFORMS_CONNECTION_STATUS.connected
  ) {
    platforms.push(SOCIAL_PLATFORMS.youtube);
  }

  return platforms;
};

export const getPlatformStatus = (creator: CreatorType) =>
  mapCreatorPlatformsFromApiToVm(creator?.platforms);

export const getMainPlatformStatus = (
  creator?: CreatorType,
): ICreatorPrimaryPlatformsStatus => {
  const platforms = mapCreatorPlatformsFromApiToVm(creator?.platforms);
  const { connected, expired, shouldReAuth } = PRIMARY_SOCIAL_PLATFORMS.reduce(
    (
      acc: Pick<
        ICreatorPrimaryPlatformsStatus,
        'connected' | 'expired' | 'shouldReAuth'
      >,
      platform,
    ) => {
      const { isConnected, isTokenValid, shouldReAuth } = platforms[platform];
      const isPrimaryPlatform = PRIMARY_SOCIAL_PLATFORMS.includes(platform);

      if (isPrimaryPlatform) {
        if (isConnected) {
          acc.connected.push(platform);
          if (!isTokenValid) {
            acc.expired.push(platform);
          }
          if (shouldReAuth) {
            acc.shouldReAuth.push(platform);
          }
        }
      }

      return acc;
    },
    {
      connected: [],
      expired: [],
      shouldReAuth: [],
    },
  );

  return {
    connected,
    expired,
    hasConnected: !!connected.length,
    hasExpired: !!expired.length,
    hasReAuth: !!shouldReAuth.length,
    shouldReAuth,
  };
};

export const getPlatformsStatus = (
  creator?: CreatorType,
): ICreatorPlatformsStatus => {
  const platforms = mapCreatorPlatformsFromApiToVm(creator?.platforms);
  const { connected, expired, shouldReAuth } = Object.keys(
    SOCIAL_PLATFORMS,
  ).reduce(
    (
      acc: Pick<
        ICreatorPlatformsStatus,
        'connected' | 'expired' | 'shouldReAuth'
      >,
      platform: SOCIAL_PLATFORMS,
    ) => {
      const { isConnected, isTokenValid, shouldReAuth } = platforms[platform];

      if (isConnected) {
        acc.connected.push(platform);
        if (!isTokenValid) {
          acc.expired.push(platform);
        }
        if (shouldReAuth) {
          acc.shouldReAuth.push(platform);
        }
      }

      return acc;
    },
    {
      connected: [],
      expired: [],
      shouldReAuth: [],
    },
  );

  return {
    connected,
    expired,
    hasConnected: !!connected.length,
    hasExpired: !!expired.length,
    hasReAuth: !!shouldReAuth.length,
    shouldReAuth,
  };
};

export const getMainPlatformData = (
  creator?: CreatorType,
): ICreatorPrimaryPlatformData => {
  const { instagram, tiktok, snapchat } = mapCreatorPlatformsFromApiToVm(
    creator?.platforms,
  );

  // Undefined as otherwise it could be null and breaks fallbacks
  return {
    avatar: creator?.avatar || undefined,
    email: creator?.email || undefined,
    handle: instagram.handle || tiktok.handle || snapchat.handle || undefined,
    name: creator?.fullName || undefined,
  };
};

export const getGlobalCreatorName = ({
  firstName = '',
  lastName = '',
  platforms,
}: CreatorType) => {
  if ((!firstName || !lastName) && platforms) {
    const { instagram, tiktok } = mapCreatorPlatformsFromApiToVm(platforms);
    const [{ firstAuthAt, name }] = [instagram, tiktok].sort(
      ({ firstAuthAt: a }, { firstAuthAt: b }) =>
        a && b ? a.getTime() - b.getTime() : 0,
    );

    // Check if sorted first value has a date if not follow old logic IG > TT
    return firstAuthAt && name ? name : instagram.name || tiktok.name;
  }

  return `${firstName} ${lastName}`.trim();
};

export const isInstagramPlatformConnected = (creator: CreatorType) =>
  getPlatformStatus(creator).instagram.isConnected;

export const formatPlatformHandle = (handle: string) => {
  return handle ? `${HANDLE_PREFIX}${handle}` : '';
};
