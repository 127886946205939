import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import clientApi from '~/clientApi';
import { IUserSettings } from '~/clientApi/clientApi.api-model';
import { USER_SETTINGS_QUERY_KEY } from '~/queries/keys';

export const useUserSettings = (
  options: QueryObserverOptions<IUserSettings> = {},
) => {
  // These routes are the ones that are not using FFs yet.
  // When we add some FF to some of the routes, we should remove the routes from here.
  // The goal is to centralize the shared FF usage in one place.
  const sharedRoutes = [
    // '/shared/media-packs', // FF added to Media packs already
    // '/shared/t/', // FF added to shared lists already
    '/shared/content/',
    '/shared/manager/',
    '/shared/multiframe/',
    '/shared/saved-list/',
    '/shared/talent/',
    '/shared/tiktok-content/',
    '/shared/youtube-content/',
  ];

  const router = useRouter();
  const isShared =
    sharedRoutes.some((route) => router.pathname.includes(route)) ||
    /^\/shared\/[^/]+$/.test(router.pathname); // This pattern is to check if the route is a dynamic agency route, like /shared/whalartalent

  return useQuery(
    [USER_SETTINGS_QUERY_KEY],
    () => {
      return clientApi.getUserSettings();
    },
    {
      refetchOnWindowFocus: false,
      retry: 2,
      staleTime: 60 * 1000 * 5, // 5 minutes
      enabled: !isShared,
      ...options,
    },
  );
};
