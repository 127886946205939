import { create } from 'zustand';
import { FEATURE_FLAGS } from '~/constants';

import { getFlagValues } from './utils';

type FlagValue = {
  value: boolean;
};
export type FlagStore = {
  flags: Record<FEATURE_FLAGS, FlagValue>;
  status: 'loading' | 'error' | 'success';
  setStatus: (status: FlagStore['status']) => void;
  setFlag: (flagName: FEATURE_FLAGS, value: boolean) => void;
};

export const defaultFlagValues = getFlagValues([]);

export const useFlagStore = create<FlagStore>((set, get) => ({
  flags: defaultFlagValues,
  status: 'loading',
  setStatus: (status) => set({ status }),
  setFlag: (flagName, value) =>
    set({
      flags: {
        ...get().flags,
        [flagName]: { value },
      },
    }),
}));
