import { isNil, isObject, transform } from 'lodash';

const HYDRA_MEMBER_KEY = 'hydra:member';
const HYDRA_TOTAL_ITEMS_KEY = 'hydra:totalItems';

/**
 * Deep replace of key names in a data structure
 *
 * @param {object} obj
 * @param {object} keysMap { oldKey1: newKey1, oldKey2: newKey2, ...}
 * @returns {object} object with keys deep replaced
 */
export function replaceKeysDeep(obj, keysMap) {
  return transform(obj, function (result, value, key) {
    const currentKey = keysMap[key] || key;

    result[currentKey] = isObject(value)
      ? replaceKeysDeep(value, keysMap)
      : value;
  });
}

/**
 * Transform any hydra resource from the BE by deep replacing keys for easier manipulation in JS
 *
 * `@id` => `_id`
 * `hydra:member` => `results`
 * `hydra:totalItems` => `totalItems`
 *
 * @param {HydraEntity} data
 * @returns {ParsedHydraEntity}
 */
export function transformHydraResources(data) {
  // Ignore primitives and nil
  if (isNil(data) || !isObject(data)) {
    return data;
  }

  return replaceKeysDeep(data, {
    [HYDRA_MEMBER_KEY]: 'results',
    '@id': '_id',
    [HYDRA_TOTAL_ITEMS_KEY]: 'totalItems',
  });
}
