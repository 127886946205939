import { ButtonProps, Button as CButton } from '@chakra-ui/react';
import * as React from 'react';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <CButton
      fontSize="sm"
      fontWeight="medium"
      letterSpacing="0.004em"
      lineHeight="14px"
      ref={ref}
      rounded="full"
      textTransform="capitalize"
      transition="all 250ms ease 0s"
      {...props}
    />
  ),
);

export default Button;
