import { Box, Flex, FlexProps, useTheme } from '@chakra-ui/react';

import styles from './FoamSpinner.module.css';

export const FoamSpinner: React.FC<FlexProps> = (props) => {
  const { colors } = useTheme();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      data-testid="foam-spinner"
      {...props}
    >
      <Box as="svg" width="31px" height="41px" viewBox="0 0 31 41">
        <rect
          className={styles.topDotHorizontalBar}
          width="30"
          height="10"
          y="1"
          rx="5"
          fill={colors.primary[500]}
        />
        <rect
          className={styles.middleDotHorizontalBar}
          width="10"
          height="10"
          y="16"
          rx="5"
          fill={colors.primary[500]}
        />
        <rect
          className={styles.middleDotVerticalbar}
          width="10"
          height="25"
          y="16"
          fill={colors.primary[500]}
          rx="5"
        />
        <rect
          width="8.4"
          height="38.4"
          x=".8"
          y="1.8"
          stroke={colors.primary[500]}
          fill="none"
          strokeWidth="1.6"
          rx="4.2"
        />

        <path
          className={styles.topDot}
          fill={colors.white}
          fillRule="nonzero"
          stroke={colors.primary[500]}
          strokeWidth="1.6"
          d="M25.11,1.78 C23.9461577,1.78 22.8935216,2.25034925 22.1319354,3.01193542 C21.3703492,3.7735216 20.9,4.82615768 20.9,5.99 C20.9,7.15384232 21.3703492,8.2064784 22.1319354,8.96806458 C22.8935216,9.72965075 23.9461577,10.2 25.11,10.2 C26.2738423,10.2 27.3264784,9.72965075 28.0880646,8.96806458 C28.8496508,8.2064784 29.32,7.15384232 29.32,5.99 C29.32,4.82615768 28.8496508,3.7735216 28.0880646,3.01193542 C27.3264784,2.25034925 26.2738423,1.78 25.11,1.78 Z"
        />
        <path
          fill={colors.white}
          fillRule="nonzero"
          stroke={colors.primary[500]}
          strokeWidth="1.6"
          d="M5,31.8 C3.8391051,31.8 2.78920647,32.2702179 2.02957362,33.03137 C1.26936327,33.7931008 0.8,34.8459742 0.8,36.01 C0.8,37.1740258 1.26936327,38.2268992 2.02957362,38.98863 C2.78920647,39.7497821 3.8391051,40.22 5,40.22 C6.1608949,40.22 7.21079353,39.7497821 7.97042638,38.98863 C8.73063673,38.2268992 9.2,37.1740258 9.2,36.01 C9.2,34.8459742 8.73063673,33.7931008 7.97042638,33.03137 C7.21079353,32.2702179 6.1608949,31.8 5,31.8 Z"
        />
        <path
          className={styles.middleDot}
          fill={colors.white}
          fillRule="nonzero"
          stroke={colors.primary[500]}
          strokeWidth="1.6"
          d="M20.01,16.8 C18.8461577,16.8 17.7935216,17.2703492 17.0319354,18.0319354 C16.2703492,18.7935216 15.8,19.8461577 15.8,21.01 C15.8,22.1738423 16.2703492,23.2264784 17.0319354,23.9880646 C17.7935216,24.7496508 18.8461577,25.22 20.01,25.22 C21.1738423,25.22 22.2264784,24.7496508 22.9880646,23.9880646 C23.7496508,23.2264784 24.22,22.1738423 24.22,21.01 C24.22,19.8461577 23.7496508,18.7935216 22.9880646,18.0319354 C22.2264784,17.2703492 21.1738423,16.8 20.01,16.8 Z"
        />
      </Box>
    </Flex>
  );
};
