import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    minHeight: 8,
  },
  control: {
    outlineWidth: '2px',
    outlineStyle: 'solid',
    borderRadius: 4,
    borderWidth: '1px',
    borderColor: 'primary.700',
    _active: {
      borderColor: 'primary.600',
      outlineColor: 'primary.200',
    },
    _hover: {
      borderColor: 'primary.500',
      outlineColor: 'primary.100',
    },
    _disabled: {
      opacity: 1,
      outlineWidth: 0,
      borderColor: 'primary.200',
      backgroundColor: 'white',
      pointerEvents: 'none',
    },
    _focusVisible: {
      borderColor: 'primary.500',
      outlineColor: 'primary.100',
    },
    _checked: {
      borderColor: 'secondary.500',
      backgroundColor: 'secondary.500',
      _active: {
        borderColor: 'secondary.700',
        backgroundColor: 'secondary.700',
        outlineColor: 'secondary.100',
      },
      _disabled: {
        backgroundColor: 'secondary.100',
        outlineWidth: 0,
        pointerEvents: 'none',
      },
      _hover: {
        borderColor: 'secondary.500',
        backgroundColor: 'secondary.500',
        outlineColor: 'secondary.50',
      },
      _focusVisible: {
        borderColor: 'secondary.600',
        backgroundColor: 'secondary.600',
        outlineColor: 'secondary.50',
      },
    },
    _indeterminate: {
      borderColor: 'secondary.500',
      backgroundColor: 'secondary.500',
      _active: {
        borderColor: 'secondary.700',
        backgroundColor: 'secondary.700',
        outlineColor: 'secondary.100',
      },
      _disabled: {
        backgroundColor: 'secondary.100',
        outlineWidth: 0,
        pointerEvents: 'none',
      },
      _hover: {
        borderColor: 'secondary.500',
        backgroundColor: 'secondary.500',
        outlineColor: 'secondary.50',
      },
      _focusVisible: {
        borderColor: 'secondary.600',
        backgroundColor: 'secondary.600',
        outlineColor: 'secondary.50',
      },
    },
  },
});

const right = definePartsStyle({
  container: {
    width: '100%',
    justifyContent: 'space-between',
  },
  control: {
    marginLeft: 2,
    order: 2,
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { right },
});
