import { ApiModule } from '~/clientApi/ApiModule';

const MODULE = 'creator-tiktok-insights';

const extraEndpoints = {};

const ttInsights = (apiInstance) =>
  new ApiModule(apiInstance, MODULE, extraEndpoints);

export default ttInsights;
