import { AxiosInstance, AxiosResponse } from 'axios';
import authApi from '~/authApi';

import {
  ClientApiOptions,
  ClientApiPaginationOptions,
} from './clientApi.api-model';
import { IClientApiResponse } from './clientApi.types';
import { SavedList } from './savedLists.model';

const MODULE = 'agencies';

interface IAgencyOptions extends ClientApiOptions {
  id: string;
}

interface IAgencyListsOpions
  extends IAgencyOptions,
    ClientApiPaginationOptions {
  filter?: string[];
  name?: string[];
}

// TODO: move to this module getAgencyCreators and getAgencies from clientApi/index.js
const agencies = (apiInstance: AxiosInstance) => {
  const getLists = ({
    id,
    ...params
  }: IAgencyListsOpions): Promise<
    AxiosResponse<IClientApiResponse<SavedList>>
  > => {
    return apiInstance.get(`/${MODULE}/${id}/saved-lists`, { params });
  };

  const update = (id: string, data: any) => {
    return apiInstance.patch(`/${MODULE}/${id}`, data, {
      headers: { 'Content-Type': 'application/merge-patch+json' },
    });
  };

  const managers = ({
    agencyId,
    page,
    pageSize,
  }: {
    agencyId: string;
    page: number;
    pageSize: number;
  }): Promise<{ results: [] }> => {
    const properties: string[] = [
      'createdAt',
      'email',
      'id',
      'invitations',
      'name',
      'updatedAt',
      'registeredAt',
      'status',
      'userRoles',
    ];
    return apiInstance.get(`/${MODULE}/${agencyId}/managers`, {
      params: {
        page,
        pageSize,
        'order[createdAt]': 'desc',
        'properties[agency][]': ['name'],
        properties,
      },
    });
  };

  const invitations = ({
    agencyId,
    status = ['pending'],
  }: {
    agencyId: string;
    status?: string[];
  }): Promise<{ results: [] }> => {
    const statusQuery = status
      .map((status) => {
        return `status[]=${status}`;
      })
      .join('&');
    return apiInstance.get(`/${MODULE}/${agencyId}/invitations?${statusQuery}`);
  };

  const creators = ({
    agencyId,
    filters,
    slug,
    sort,
    page = 1,
    pageSize = 50,
    management,
  }: {
    agencyId: string;
    filters?: string[];
    slug?: string;
    sort?: string;
    page?: number;
    pageSize?: number;
    management?: boolean;
  }): Promise<{ results: [] }> => {
    let headers;
    if (!authApi.user?.id || Boolean(slug)) {
      headers = {
        'X-AUTH-TOKEN': `agency-share-${slug}`,
        authorization: '',
      };
    }

    return apiInstance.get(`/agencies/${agencyId}/creators`, {
      headers,
      params: {
        'filter[]': filters,
        'sort[]': sort,
        page,
        pageSize,
        management,
      },
    });
  };

  const removeCreator = ({
    agencyId,
    creatorId,
  }: {
    agencyId: string;
    creatorId: string;
  }): Promise<{ results: [] }> => {
    return apiInstance.delete(
      `/${MODULE}/${agencyId}/creator-access/${creatorId}`,
    );
  };

  const removeManager = (managerId: string): Promise<{ results: [] }> => {
    return apiInstance.delete(`/${MODULE}/manager/${managerId}`);
  };

  return {
    getLists,
    update,
    managers,
    creators,
    invitations,
    removeCreator,
    removeManager,
  };
};

export default agencies;
