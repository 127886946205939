export const PROVIDER_ERROR =
  'If you area reading this, likely you forgot to add the auth provider on top of your app';

export enum STATUS {
  ready = 'ready',
  redirecting = 'redirecting',
  loading = 'loading',
}

export const SKIP_RECONNECT_SESSION_KEY = 'skip-reconnect';
