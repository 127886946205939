import {
  agencyRoutes,
  demoRoutes,
  echoRoutes,
  settingsRoutes,
  ssoRoutes,
} from './groups';
import { generatePath } from './router.helpers';
import { Routes, SIGNUP_ROUTES, SwitchRoutes } from './router.types';

export const switchRoutes: SwitchRoutes = {
  agency: '[agency]',
  connectionDashboard: '/connection-dashboard',
  connectionDashboardEntry: '/connection-dashboard/[token]',
  connectionDashboardLogin: '/connection-dashboard/[token]/login',
  connectionDashboardSignup: '/connection-dashboard/[token]/create-password',
  contentSearch: '/content-search',
  creatorBrandDeals: '/creator/brand-deals',
  creatorCampaignReportList: '/campaign-report',
  creatorLiveContent: '/live-content',
  creatorLiveContentId: '/live-content/[id]',
  creatorliveContentIdTikTok: `/live-content/tiktok/[id]`,
  creatorliveContentIdYouTube: `/live-content/youtube/[id]`,
  creatorMediaPackList: '/media-packs',
  creatorMultiframeStories: '/multiframe-stories',
  echoWelcomeScreen: '/welcome-existing-whalar',
  emailAssistant: '/email-assistant',
  emailAssistantResult: '/email-assistant/result',
  extensionAuthCallback: '/oauth/extension',
  extensionAuthCallbackToTutorial: '/oauth/extension/tutorial',
  foamAi: '/foam-ai',
  logout: '/logout',
  managerDashboard: '/dashboard',
  managerRoster: '/roster',
  mediaPackChooseTemplate: '/media-packs/choose-template',
  mediaPackDetail: '/media-packs/[id]',
  mediaPacks: '/media-packs',
  mediaPackSharedView: '/media-packs/[id]/share',
  myAgency: '/my-agency',
  myRoster: '/my-roster',
  notFound: '/404',
  unauthorized: '/401',
  profileOverview: '/t/[creatorSlug]',
  sharedProfileOverview: '/shared/talent/[creatorSlug]',
  platformConnections: '/platform-connections',
  privacyPolicy: '/privacy',
  reconnectPlatforms: '/reconnect-platforms',
  root: '/',
  savedLists: '/saved-lists',
  savedListDetail: '/saved-lists/[id]',
  sharedMediaPack: '/shared/media-packs/[sharedToken]',
  sharedMediaPackLiveContent:
    '/shared/media-packs/[sharedToken]/live-content/[sid]',
  signUpConnectionSuccess: SIGNUP_ROUTES.signUpConnectionSuccess,
  signUpConnectSocials: SIGNUP_ROUTES.signUpConnectSocials,
  termsAndConditions: '/terms',
  slackCallback: `${process.env.NEXT_PUBLIC_SLACK_HOST ?? ''}/oauth/callback`,
  slackAuthCallback: '/oauth/slack',
  ...echoRoutes,
  ...settingsRoutes,
  ...ssoRoutes,
  ...demoRoutes,
  ...agencyRoutes,
};

export const routes: Routes = {
  ...switchRoutes,
  connectionDashboardEntry: (token) =>
    generatePath(switchRoutes.connectionDashboardEntry, {
      params: { token },
    }),
  connectionDashboardLogin: (token) =>
    generatePath(switchRoutes.connectionDashboardLogin, {
      params: { token },
    }),
  connectionDashboardSignup: (token) =>
    generatePath(switchRoutes.connectionDashboardSignup, {
      params: { token },
    }),

  mediaPackDetail: (id: string, firstTime = false) =>
    generatePath(switchRoutes.mediaPackDetail, {
      params: { id },
      query: { firstTime },
    }),
  mediaPackShareView: (id: string) =>
    generatePath(switchRoutes.mediaPackSharedView, {
      params: { id },
    }),
  creatorLiveContentId: (id: string) =>
    generatePath(switchRoutes.creatorLiveContentId, {
      params: { id },
    }),
  creatorliveContentIdTikTok: (contentId: string, platform) =>
    generatePath(switchRoutes.creatorliveContentIdTikTok, {
      params: { id: contentId, platform },
    }),
  creatorliveContentIdYouTube: (contentId: string, platform) =>
    generatePath(switchRoutes.creatorliveContentIdYouTube, {
      params: { id: contentId, platform },
    }),
  profileOverview: (creatorSlug: string) =>
    generatePath(switchRoutes.profileOverview, {
      params: { creatorSlug },
    }),
  sharedProfileOverview: (creatorSlug: string) =>
    generatePath(switchRoutes.sharedProfileOverview, {
      params: { creatorSlug },
    }),
  sharedMediaPack: (sharedToken: string) =>
    generatePath(switchRoutes.sharedMediaPack, {
      params: { sharedToken },
    }),
  sharedMediaPackLiveContent: (sharedToken: string, sid: string) =>
    generatePath(switchRoutes.sharedMediaPackLiveContent, {
      params: { sharedToken, sid },
    }),
  slackCallback: (
    accessToken: string,
    refreshToken: string,
    userId: string,
    state: string,
  ) =>
    generatePath(switchRoutes.slackCallback, {
      query: { userId, state, accessToken, refreshToken },
    }),
  slackAuthCallback: (userId: string, state: string) =>
    generatePath(switchRoutes.slackAuthCallback, {
      query: { userId, state },
    }),
  savedListDetail: (id: string) =>
    generatePath(switchRoutes.savedListDetail, {
      params: { id },
    }),
  contentSearchDetailModal: (id: string) =>
    `${routes.contentSearch}?contentId=${id}`,
};
