export const globalStyles = (props) => ({
  html: { height: '100%', scrollBehavior: 'smooth' },
  body: {
    height: '100%',
    width: '100%',
    [`@media (min-width: ${props.theme.breakpoints.md})`]: {
      width: 'auto',
    },
  },
  '#__next': { height: '100%' },
  '.grecaptcha-badge': {
    bottom: '100px !important',
  },

  '*, *::before, *::after': { borderColor: 'gray.400' },
  ':root': {
    '--font-family': props.theme.fonts.body,
  },
  /* Scrollbar styling for scrollable content */

  /* Works on Firefox */
  '.scrollbar-thin *': {
    scrollbarWidth: 'thin',
    scrollbarColor: `${props.theme.colors.primary[200]} transparent`,
  },

  /* Works on Chrome, Edge, and Safari */
  '.scrollbar-thin *::-webkit-scrollbar': {
    width: '4px',
  },

  '.scrollbar-thin *::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '.scrollbar-thin *::-webkit-scrollbar-thumb': {
    backgroundColor: props.theme.colors.primary[200],
    borderRadius: '40px',
  },
  /* End scrollbar styling*/
});
