export const AGENCIES_COLLECTION_QUERY_KEY = 'agencies';
export const AGENCY_TOKEN = 'agency-token';

export const CREATOR_BRAND_ENTITY_QUERY_KEY = 'creator-brand';
export const CREATOR_BRAND_COLLECTION_QUERY_KEY = 'creator-brands';

export const CONTENT_ENTITY_QUERY_KEY = 'content';
export const CONTENTS_COLLECTION_QUERY_KEY = 'contents';

export const CONTENTS_PDF_COLLECTION_QUERY_KEY = 'pdf-list';

export const GET_CONTENT_QUERY_KEY = 'get-content';

export const COUNTRIES_COLLECTION_QUERY_KEY = 'countries';

export const MULTIFRAME_ENTITY_QUERY_KEY = 'multiframe';
export const MULTIFRAMES_COLLECTION_QUERY_KEY = 'multiframes';

export const INSIGHTS_COLLECTION_QUERY_KEY = 'insights';

export const CAMPAIGN_REPORT_ENTITY_QUERY_KEY = 'campaign-report';
export const CAMPAIGN_REPORTS_COLLECTION_QUERY_KEY = 'campaign-reports';

export const MEDIA_PACK_ENTITY_QUERY_KEY = 'media-pack';
export const MEDIA_PACKS_COLLECTION_QUERY_KEY = 'media-packs';

export const VISIBILITY_ENTITY_QUERY_KEY = 'visibility';

export const BENCHMARK_ENTITY_QUERY_KEY = 'benchmark';

export const CREATOR_INSIGHT_ENTITY_QUERY_KEY = 'creator-insight';

export const INVITATIONS_COLLECTION_QUERY_KEY = 'invitations';
export const ACCEPTED_INVITATIONS_COLLECTION_QUERY_KEY = 'accepted-invitations';

export const MANAGER_ENTITY_QUERY_KEY = 'manager';
export const MANAGERS_COLLECTION_QUERY_KEY = 'managers';

export const CREATOR_ENTITY_QUERY_KEY = 'creator';
export const CREATORS_COLLECTION_QUERY_KEY = 'creators';
export const MANAGED_CREATORS_COLLECTION_QUERY_KEY = 'managed-creators';
export const MANAGED_OP_CREATORS_COLLECTION_QUERY_KEY = 'managed-op-creators';

export const YT_CONTENT_ENTITY_QUERY_KEY = 'yt-content';
export const YT_CONTENTS_COLLECTION_QUERY_KEY = 'yt-contents';

export const YT_CHANNEL_ENTITY_QUERY_KEY = 'yt-channel';
export const YT_CHANNELS_COLLECTION_QUERY_KEY = 'yt-channels';

export const NOTIFICATIONS_COLLECTION_QUERY_KEY = 'notifications';
export const NOTIFICATIONS_ENTITY_QUERY_KEY = 'notification';

export const NOTIFICATIONS_AD_CONTENT_QUERY_KEY = 'notifications-ad-content';
export const NOTIFICATIONS_MILESTONES_QUERY_KEY = 'notifications-milestones';

export const SETTINGS_COLLECTION_QUERY_KEY = 'settings';
export const SETTINGS_ENTITY_QUERY_KEY = 'setting';

export const SETTINGS_AD_CONTENT_QUERY_KEY = 'notify-ad-content';
export const SETTINGS_MILESTONES_QUERY_KEY = 'notify-creator-milestones';
export const USER_SETTINGS_QUERY_KEY = 'user-settings';

export const NOTIFICATIONS_SUMMARY_QUERY_KEY = 'notifications-summary';

export const TIKTOK_CONTENT_QUERY_KEY = 'tiktok-content';
export const TIKTOK_INSIGHTS_QUERY_KEY = 'tiktok-insights';

export const YOUTUBE_CHANNEL_INSIGHTS_QUERY_KEY = 'youtube-channel-insights';

export const USER_METRICS_QUERY_KEY = 'user-metrics';

export const USER_INVITATION_STATUS_QUERY_KEY = 'invitation-status';

export const USER_ECHO_MESSAGES_QUERY_KEY = 'echo-message-count';

export const BENCHMARK_COLLECTION_QUERY_KEY = 'benchmarks';

export const GMAIL_ASSISTANT_QUERY_KEY = 'assistant';

export const LOCATION_SEARCH = 'location-search';
export const CREATOR_PROFILE_QUERY_KEY = 'creator-profile';

export const CREATOR_CONTENT_QUERY_KEY = 'creator-content';

export const TR_CREATORS_LIST_QUERY_KEY = 'tr-creators-list';

export const SAVED_LISTS = 'saved-list';
export const AGENCY_SAVED_LISTS = 'agency-saved-list';
export const SAVED_LIST_CREATORS = 'saved-list-creators';
export const SHARED_SAVED_LISTS = 'shared-saved-list';

export const USER_ACCESS_QUERY_KEY = 'user-access';

export const AGENCY_MANAGEMENT_CREATORS_COLLECTION_QUERY_KEY =
  'agency-management-creators';
export const AGENCY_MANAGERS_COLLECTION_QUERY_KEY = 'agency-managers';
export const CREATORS_INVITATIONS_QUERY_KEY = 'talent-invitations';

export const CREATOR_SEARCH_OFF_PLATFORM_QUERY_KEY =
  'creator-search-off-platform-query';
export const SHARE_LINKS_KEY = 'share-links';
export const LOCATION_DETAILS_KEY = 'location-details';
export const LOCATION_SUGGESTIONS_KEY = 'location-suggestions';
