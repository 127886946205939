const shared = (apiInstance) => {
  /**
   * API upload asset method.
   * @param {String} args.email - Email (only used for keeping track)
   * @param {String} args.recaptcha - Google Recaptcha key
   * @param {String} args._sharedToken - Shared token. which should get removed in the interceptor
   * @returns {Promise}
   */
  const access = ({ email, recaptcha, _sharedToken }) => {
    return apiInstance.post(`/log/access`, { email, recaptcha, _sharedToken });
  };

  return {
    access,
  };
};

export default shared;
