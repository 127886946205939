import { SwitchOptionalParams, SwitchParams } from './router.types';

interface GeneratePathOptions {
  params?: SwitchParams;
  query?: SwitchOptionalParams;
}

export const generatePath = (
  route: string,
  { params = {}, query = {} }: GeneratePathOptions,
): string => {
  const queryParams = Object.entries(query).reduce(
    (acc: string[], [key, value]) => {
      if (value) {
        if (typeof value === 'boolean') {
          acc.push(key);
        } else {
          acc.push(`${key}=${Array.isArray(value) ? value.join(',') : value}`);
        }
      }
      return acc;
    },
    [],
  );
  Object.entries(params).forEach(([name, param]) => {
    route = route.replace(
      `[${name}]`,
      Array.isArray(param) ? param.join('/') : param,
    );
  });
  if (queryParams.length) {
    route += `?${queryParams.join('&')}`;
  }
  return route;
};
