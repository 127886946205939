export enum INTERCOM_EVENTS {
  boot = 'boot',
  shutdown = 'shutdown',
  show = 'show',
}

export enum METRICS_INTERCOM_NAMES {
  userId = 'user_id',
  userEmail = 'email',
  userHash = 'user_hash',
}
