import React from 'react';
import type { TALENT_DROPDOWN_VALUES } from 'ui-components/dist/TalentDropdown';
import type { REDIRECT_DATA_STORAGE_KEY } from '~/constants/redirect';
import type {
  OPPORTUNITY_RESULT_KEY,
  OPPORTUNITY_TEXT_KEY,
} from '~/contexts/Opportunity/Opportunity.constants';
import type { SKIP_RECONNECT_SESSION_KEY } from '~/contexts/auth/auth.constants';
import type { IAuthSlackScreenParams } from '~/screens/AuthSlackScreen';
import type { IOpportunityResponse } from '~/screens/EmailCampaignInputScreen/EmailCampaignInputScreen.types';

export const CONTENT_SEARCH_WILDCARD_REMINDER_DISMISSED =
  'content-search-wildcard-reminder-dismissed';

export interface IPersistedStates {
  talentOption: TALENT_DROPDOWN_VALUES;
  [SKIP_RECONNECT_SESSION_KEY]: boolean;
  [OPPORTUNITY_TEXT_KEY]: string;
  [OPPORTUNITY_RESULT_KEY]: IOpportunityResponse;
  [REDIRECT_DATA_STORAGE_KEY]: object | undefined;
  'auth-slack-storage': IAuthSlackScreenParams;
  [CONTENT_SEARCH_WILDCARD_REMINDER_DISMISSED]: boolean;
  sortTeamTableDirections: { id: string; desc: boolean }[];
  sortTalentTableDirections: { id: string; desc: boolean }[];
}

const jsonParse = <T>(value: T) => {
  try {
    // @ts-ignore
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const usePersistedState = <ValueType>(
  key: keyof IPersistedStates,
  defaultValue?: ValueType,
  storage = typeof window === 'undefined' ? undefined : window.localStorage,
): [ValueType, React.Dispatch<React.SetStateAction<ValueType>>] => {
  const storageData = storage?.getItem(key);

  const [state, setState] = React.useState<ValueType>(() =>
    storageData ? jsonParse(storageData) : defaultValue,
  );

  React.useEffect(() => {
    storage?.setItem(key, JSON.stringify(state));
  }, [key, state, storage]);

  return [state, setState];
};
